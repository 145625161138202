.selectr-selected {
    border-radius: 0;
    border-color: map-deep-get($colors, "black", "default");
    padding: 9px 28px 8px 14px;

    &::before {
        border: none;
        content: "\f107";
        font-family: "Font Awesome 5 Pro";
        height: auto;
        transform: translateY(-50%);
        width: auto;
    }
}

.selectr-label {
    font-family: Lemance;
    font-size: 14px;
    line-height: 23px;
    padding-right: 10px;
}

.selectr-container.native-open,
.selectr-container.open {
    .selectr-selected::before {
        border: none;
        content: "\f106";
        height: auto;
        transform: translateY(-50%);
        width: auto;
    }
}

.sort__form {
    padding-bottom: 20px;
    padding-top: 20px;
    display: flex;
    height: 100%;

    @include media-breakpoint-up(md) {
        display: inline-block;
        margin-bottom: 0;
        margin-top: 0;
    }

    /* stylelint-disable */
    // structuur vanauit form builder

    .form__group {
        display: inline-flex;
        flex-grow: 2;
        align-items: center;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
            flex-grow: 0;
            margin-bottom: 20px;
        }
    }

    .form__label {
        display: inline-block;
        margin-right: 10px;
        font-weight: 800;
    }

    .form__select-wrapper {
        display: inline-block;
        flex-grow: 2;

        @include media-breakpoint-up(md) {
            flex-grow: 0;
            width:175px;
        }

        @include media-breakpoint-up(lg) {
            width:210px;
        }
    }
    /* stylelint-enable */
}