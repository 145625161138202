/*stylelint-disable*/
@font-face {
  font-family: 'Calima';
  src: local("CalimaRegular"), url("../fonts/CalimaRegular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Calima';
  src: local("CalimaSemibold"), url("../fonts/CalimaSemibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Calima';
  src: local("CalimaHeavy"), url("../fonts/CalimaHeavy.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

/*stylelint-enable*/
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Calima", "Arial", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  text-align: left;
  background-color: #ffffff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #004a99;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #00254d;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container, .header__container,
.container-fluid,
.container-lg,
.navigation__container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 992px) {
  .container, .header__container, .container-sm, .container-md, .container-lg {
    max-width: 1200px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col-17, .col-18, .col-19, .col-20, .col-21, .col-22, .col-23, .col-24, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl-17, .col-xl-18, .col-xl-19, .col-xl-20, .col-xl-21, .col-xl-22, .col-xl-23, .col-xl-24, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-13, .col-xxl-14, .col-xxl-15, .col-xxl-16, .col-xxl-17, .col-xxl-18, .col-xxl-19, .col-xxl-20, .col-xxl-21, .col-xxl-22, .col-xxl-23, .col-xxl-24, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 4.16667%;
  max-width: 4.16667%; }

.col-2 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-3 {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.col-4 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-5 {
  flex: 0 0 20.83333%;
  max-width: 20.83333%; }

.col-6 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-7 {
  flex: 0 0 29.16667%;
  max-width: 29.16667%; }

.col-8 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-9 {
  flex: 0 0 37.5%;
  max-width: 37.5%; }

.col-10 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-11 {
  flex: 0 0 45.83333%;
  max-width: 45.83333%; }

.col-12 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-13 {
  flex: 0 0 54.16667%;
  max-width: 54.16667%; }

.col-14 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-15 {
  flex: 0 0 62.5%;
  max-width: 62.5%; }

.col-16 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-17 {
  flex: 0 0 70.83333%;
  max-width: 70.83333%; }

.col-18 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-19 {
  flex: 0 0 79.16667%;
  max-width: 79.16667%; }

.col-20 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-21 {
  flex: 0 0 87.5%;
  max-width: 87.5%; }

.col-22 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-23 {
  flex: 0 0 95.83333%;
  max-width: 95.83333%; }

.col-24 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 25; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.order-13 {
  order: 13; }

.order-14 {
  order: 14; }

.order-15 {
  order: 15; }

.order-16 {
  order: 16; }

.order-17 {
  order: 17; }

.order-18 {
  order: 18; }

.order-19 {
  order: 19; }

.order-20 {
  order: 20; }

.order-21 {
  order: 21; }

.order-22 {
  order: 22; }

.order-23 {
  order: 23; }

.order-24 {
  order: 24; }

.offset-1 {
  margin-left: 4.16667%; }

.offset-2 {
  margin-left: 8.33333%; }

.offset-3 {
  margin-left: 12.5%; }

.offset-4 {
  margin-left: 16.66667%; }

.offset-5 {
  margin-left: 20.83333%; }

.offset-6 {
  margin-left: 25%; }

.offset-7 {
  margin-left: 29.16667%; }

.offset-8 {
  margin-left: 33.33333%; }

.offset-9 {
  margin-left: 37.5%; }

.offset-10 {
  margin-left: 41.66667%; }

.offset-11 {
  margin-left: 45.83333%; }

.offset-12 {
  margin-left: 50%; }

.offset-13 {
  margin-left: 54.16667%; }

.offset-14 {
  margin-left: 58.33333%; }

.offset-15 {
  margin-left: 62.5%; }

.offset-16 {
  margin-left: 66.66667%; }

.offset-17 {
  margin-left: 70.83333%; }

.offset-18 {
  margin-left: 75%; }

.offset-19 {
  margin-left: 79.16667%; }

.offset-20 {
  margin-left: 83.33333%; }

.offset-21 {
  margin-left: 87.5%; }

.offset-22 {
  margin-left: 91.66667%; }

.offset-23 {
  margin-left: 95.83333%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-sm-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-sm-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-sm-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-sm-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-sm-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-sm-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-sm-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-sm-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-sm-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 25; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .order-sm-13 {
    order: 13; }
  .order-sm-14 {
    order: 14; }
  .order-sm-15 {
    order: 15; }
  .order-sm-16 {
    order: 16; }
  .order-sm-17 {
    order: 17; }
  .order-sm-18 {
    order: 18; }
  .order-sm-19 {
    order: 19; }
  .order-sm-20 {
    order: 20; }
  .order-sm-21 {
    order: 21; }
  .order-sm-22 {
    order: 22; }
  .order-sm-23 {
    order: 23; }
  .order-sm-24 {
    order: 24; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 4.16667%; }
  .offset-sm-2 {
    margin-left: 8.33333%; }
  .offset-sm-3 {
    margin-left: 12.5%; }
  .offset-sm-4 {
    margin-left: 16.66667%; }
  .offset-sm-5 {
    margin-left: 20.83333%; }
  .offset-sm-6 {
    margin-left: 25%; }
  .offset-sm-7 {
    margin-left: 29.16667%; }
  .offset-sm-8 {
    margin-left: 33.33333%; }
  .offset-sm-9 {
    margin-left: 37.5%; }
  .offset-sm-10 {
    margin-left: 41.66667%; }
  .offset-sm-11 {
    margin-left: 45.83333%; }
  .offset-sm-12 {
    margin-left: 50%; }
  .offset-sm-13 {
    margin-left: 54.16667%; }
  .offset-sm-14 {
    margin-left: 58.33333%; }
  .offset-sm-15 {
    margin-left: 62.5%; }
  .offset-sm-16 {
    margin-left: 66.66667%; }
  .offset-sm-17 {
    margin-left: 70.83333%; }
  .offset-sm-18 {
    margin-left: 75%; }
  .offset-sm-19 {
    margin-left: 79.16667%; }
  .offset-sm-20 {
    margin-left: 83.33333%; }
  .offset-sm-21 {
    margin-left: 87.5%; }
  .offset-sm-22 {
    margin-left: 91.66667%; }
  .offset-sm-23 {
    margin-left: 95.83333%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-md-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-md-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-md-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-md-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-md-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-md-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-md-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-md-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-md-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-md-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-md-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-md-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 25; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .order-md-13 {
    order: 13; }
  .order-md-14 {
    order: 14; }
  .order-md-15 {
    order: 15; }
  .order-md-16 {
    order: 16; }
  .order-md-17 {
    order: 17; }
  .order-md-18 {
    order: 18; }
  .order-md-19 {
    order: 19; }
  .order-md-20 {
    order: 20; }
  .order-md-21 {
    order: 21; }
  .order-md-22 {
    order: 22; }
  .order-md-23 {
    order: 23; }
  .order-md-24 {
    order: 24; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 4.16667%; }
  .offset-md-2 {
    margin-left: 8.33333%; }
  .offset-md-3 {
    margin-left: 12.5%; }
  .offset-md-4 {
    margin-left: 16.66667%; }
  .offset-md-5 {
    margin-left: 20.83333%; }
  .offset-md-6 {
    margin-left: 25%; }
  .offset-md-7 {
    margin-left: 29.16667%; }
  .offset-md-8 {
    margin-left: 33.33333%; }
  .offset-md-9 {
    margin-left: 37.5%; }
  .offset-md-10 {
    margin-left: 41.66667%; }
  .offset-md-11 {
    margin-left: 45.83333%; }
  .offset-md-12 {
    margin-left: 50%; }
  .offset-md-13 {
    margin-left: 54.16667%; }
  .offset-md-14 {
    margin-left: 58.33333%; }
  .offset-md-15 {
    margin-left: 62.5%; }
  .offset-md-16 {
    margin-left: 66.66667%; }
  .offset-md-17 {
    margin-left: 70.83333%; }
  .offset-md-18 {
    margin-left: 75%; }
  .offset-md-19 {
    margin-left: 79.16667%; }
  .offset-md-20 {
    margin-left: 83.33333%; }
  .offset-md-21 {
    margin-left: 87.5%; }
  .offset-md-22 {
    margin-left: 91.66667%; }
  .offset-md-23 {
    margin-left: 95.83333%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-lg-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-lg-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-lg-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-lg-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-lg-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-lg-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-lg-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-lg-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-lg-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-lg-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-lg-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 25; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .order-lg-13 {
    order: 13; }
  .order-lg-14 {
    order: 14; }
  .order-lg-15 {
    order: 15; }
  .order-lg-16 {
    order: 16; }
  .order-lg-17 {
    order: 17; }
  .order-lg-18 {
    order: 18; }
  .order-lg-19 {
    order: 19; }
  .order-lg-20 {
    order: 20; }
  .order-lg-21 {
    order: 21; }
  .order-lg-22 {
    order: 22; }
  .order-lg-23 {
    order: 23; }
  .order-lg-24 {
    order: 24; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 4.16667%; }
  .offset-lg-2 {
    margin-left: 8.33333%; }
  .offset-lg-3 {
    margin-left: 12.5%; }
  .offset-lg-4 {
    margin-left: 16.66667%; }
  .offset-lg-5 {
    margin-left: 20.83333%; }
  .offset-lg-6 {
    margin-left: 25%; }
  .offset-lg-7 {
    margin-left: 29.16667%; }
  .offset-lg-8 {
    margin-left: 33.33333%; }
  .offset-lg-9 {
    margin-left: 37.5%; }
  .offset-lg-10 {
    margin-left: 41.66667%; }
  .offset-lg-11 {
    margin-left: 45.83333%; }
  .offset-lg-12 {
    margin-left: 50%; }
  .offset-lg-13 {
    margin-left: 54.16667%; }
  .offset-lg-14 {
    margin-left: 58.33333%; }
  .offset-lg-15 {
    margin-left: 62.5%; }
  .offset-lg-16 {
    margin-left: 66.66667%; }
  .offset-lg-17 {
    margin-left: 70.83333%; }
  .offset-lg-18 {
    margin-left: 75%; }
  .offset-lg-19 {
    margin-left: 79.16667%; }
  .offset-lg-20 {
    margin-left: 83.33333%; }
  .offset-lg-21 {
    margin-left: 87.5%; }
  .offset-lg-22 {
    margin-left: 91.66667%; }
  .offset-lg-23 {
    margin-left: 95.83333%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xl-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xl-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xl-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xl-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xl-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xl-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xl-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xl-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xl-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xl-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xl-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 25; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .order-xl-13 {
    order: 13; }
  .order-xl-14 {
    order: 14; }
  .order-xl-15 {
    order: 15; }
  .order-xl-16 {
    order: 16; }
  .order-xl-17 {
    order: 17; }
  .order-xl-18 {
    order: 18; }
  .order-xl-19 {
    order: 19; }
  .order-xl-20 {
    order: 20; }
  .order-xl-21 {
    order: 21; }
  .order-xl-22 {
    order: 22; }
  .order-xl-23 {
    order: 23; }
  .order-xl-24 {
    order: 24; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 4.16667%; }
  .offset-xl-2 {
    margin-left: 8.33333%; }
  .offset-xl-3 {
    margin-left: 12.5%; }
  .offset-xl-4 {
    margin-left: 16.66667%; }
  .offset-xl-5 {
    margin-left: 20.83333%; }
  .offset-xl-6 {
    margin-left: 25%; }
  .offset-xl-7 {
    margin-left: 29.16667%; }
  .offset-xl-8 {
    margin-left: 33.33333%; }
  .offset-xl-9 {
    margin-left: 37.5%; }
  .offset-xl-10 {
    margin-left: 41.66667%; }
  .offset-xl-11 {
    margin-left: 45.83333%; }
  .offset-xl-12 {
    margin-left: 50%; }
  .offset-xl-13 {
    margin-left: 54.16667%; }
  .offset-xl-14 {
    margin-left: 58.33333%; }
  .offset-xl-15 {
    margin-left: 62.5%; }
  .offset-xl-16 {
    margin-left: 66.66667%; }
  .offset-xl-17 {
    margin-left: 70.83333%; }
  .offset-xl-18 {
    margin-left: 75%; }
  .offset-xl-19 {
    margin-left: 79.16667%; }
  .offset-xl-20 {
    margin-left: 83.33333%; }
  .offset-xl-21 {
    margin-left: 87.5%; }
  .offset-xl-22 {
    margin-left: 91.66667%; }
  .offset-xl-23 {
    margin-left: 95.83333%; } }

@media (min-width: 1600px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xxl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xxl-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xxl-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xxl-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xxl-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xxl-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xxl-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xxl-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xxl-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xxl-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xxl-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xxl-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 25; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .order-xxl-13 {
    order: 13; }
  .order-xxl-14 {
    order: 14; }
  .order-xxl-15 {
    order: 15; }
  .order-xxl-16 {
    order: 16; }
  .order-xxl-17 {
    order: 17; }
  .order-xxl-18 {
    order: 18; }
  .order-xxl-19 {
    order: 19; }
  .order-xxl-20 {
    order: 20; }
  .order-xxl-21 {
    order: 21; }
  .order-xxl-22 {
    order: 22; }
  .order-xxl-23 {
    order: 23; }
  .order-xxl-24 {
    order: 24; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 4.16667%; }
  .offset-xxl-2 {
    margin-left: 8.33333%; }
  .offset-xxl-3 {
    margin-left: 12.5%; }
  .offset-xxl-4 {
    margin-left: 16.66667%; }
  .offset-xxl-5 {
    margin-left: 20.83333%; }
  .offset-xxl-6 {
    margin-left: 25%; }
  .offset-xxl-7 {
    margin-left: 29.16667%; }
  .offset-xxl-8 {
    margin-left: 33.33333%; }
  .offset-xxl-9 {
    margin-left: 37.5%; }
  .offset-xxl-10 {
    margin-left: 41.66667%; }
  .offset-xxl-11 {
    margin-left: 45.83333%; }
  .offset-xxl-12 {
    margin-left: 50%; }
  .offset-xxl-13 {
    margin-left: 54.16667%; }
  .offset-xxl-14 {
    margin-left: 58.33333%; }
  .offset-xxl-15 {
    margin-left: 62.5%; }
  .offset-xxl-16 {
    margin-left: 66.66667%; }
  .offset-xxl-17 {
    margin-left: 70.83333%; }
  .offset-xxl-18 {
    margin-left: 75%; }
  .offset-xxl-19 {
    margin-left: 79.16667%; }
  .offset-xxl-20 {
    margin-left: 83.33333%; }
  .offset-xxl-21 {
    margin-left: 87.5%; }
  .offset-xxl-22 {
    margin-left: 91.66667%; }
  .offset-xxl-23 {
    margin-left: 95.83333%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1600px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 5px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 20px;
  height: 14px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 20px;
    height: 2px;
    background-color: #000000;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -6px; }
  .hamburger-inner::after {
    bottom: -6px; }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger {
  z-index: 11; }
  .hamburger--inverted .hamburger-inner {
    background-color: #000000; }
    .hamburger--inverted .hamburger-inner::after, .hamburger--inverted .hamburger-inner::before {
      background-color: #000000; }

.b-lazy {
  opacity: 0;
  transition: all 0.3s ease-in-out; }
  .b-lazy.b-loaded {
    opacity: 1; }

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: 0; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: #fff;
  cursor: pointer; }

.flickity-button:focus {
  outline: 0;
  box-shadow: 0 0 0 5px #19f; }

.flickity-button:active {
  opacity: .6; }

.flickity-button:disabled {
  opacity: .3;
  cursor: auto;
  pointer-events: none; }

.flickity-button-icon {
  fill: #333; }

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: .25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

.flickity-viewport {
  transition: height .3s ease-in-out; }

.basicLightbox {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  opacity: .01;
  transition: opacity 0.4s ease;
  z-index: 1000;
  will-change: opacity; }
  .basicLightbox--visible {
    opacity: 1; }
  .basicLightbox__placeholder {
    max-width: 100%;
    transform: scale(0.9);
    transition: transform 0.4s ease;
    z-index: 1;
    will-change: transform; }
    .basicLightbox__placeholder > img:first-child:last-child,
    .basicLightbox__placeholder > video:first-child:last-child,
    .basicLightbox__placeholder > iframe:first-child:last-child {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      max-width: 95%;
      max-height: 95%; }
    .basicLightbox__placeholder > video:first-child:last-child,
    .basicLightbox__placeholder > iframe:first-child:last-child {
      pointer-events: auto; }
    .basicLightbox__placeholder > img:first-child:last-child,
    .basicLightbox__placeholder > video:first-child:last-child {
      width: auto;
      height: auto; }
  .basicLightbox--img .basicLightbox__placeholder,
  .basicLightbox--video .basicLightbox__placeholder,
  .basicLightbox--iframe .basicLightbox__placeholder {
    width: 100%;
    height: 100%;
    pointer-events: none; }
  .basicLightbox--visible .basicLightbox__placeholder {
    transform: scale(1); }

/* stylelint-disable */
#CybotCookiebotDialog #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-bottom-color: #004a99 !important;
  color: #004a99 !important; }

#CybotCookiebotDialog #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover, #CybotCookiebotDialog #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:focus-visible {
  color: #004a99 !important; }

#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink,
#CybotCookiebotDialog #CybotCookiebotDialogBodyEdgeMoreDetailsLink,
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonsSelectPane label:not([for=CybotCookiebotDialogBodyLevelButtonNecessary]) .CybotCookiebotDialogBodyLevelButtonDescription:hover,
#CybotCookiebotDialog #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount):hover {
  color: #004a99 !important; }

#CybotCookiebotDialog #CybotCookiebotDialogBodyEdgeMoreDetailsLink::after {
  border-color: #004a99 !important;
  position: relative;
  top: .0625rem; }

#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton {
  transition: all 0.3s ease-in-out; }
  #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover, #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:focus-visible {
    color: #004a99 !important; }
    #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover:before, #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:focus-visible:before {
      border-color: #004a99 !important; }
  #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton::before {
    transition: all 0.3s ease-in-out; }

#CybotCookiebotDialog .CybotCookiebotDialogBodyButton {
  border-color: #004a99 !important;
  transition: all 0.3s ease-in-out; }
  #CybotCookiebotDialog .CybotCookiebotDialogBodyButton:hover, #CybotCookiebotDialog .CybotCookiebotDialogBodyButton:focus-visible {
    color: #004a99;
    background-color: rgba(0, 74, 153, 0.05) !important;
    border-color: rgba(0, 74, 153, 0.75) !important;
    opacity: 1 !important; }

#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background-color: #ffffff !important;
  color: #004a99 !important; }
  #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll:hover, #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll:focus-visible {
    background-color: rgba(0, 74, 153, 0.05) !important;
    border-color: rgba(0, 74, 153, 0.75) !important;
    color: #004a99 !important;
    opacity: 1 !important; }

#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a {
  color: #004a99 !important; }

#CybotCookiebotDialog input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: #004a99 !important; }

#CybotCookiebotDialog input[type=checkbox],
#CybotCookiebotDialog input[type=radio] {
  z-index: 0; }

#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list svg {
  fill: #004a99 !important; }

#CookiebotWidget .CookiebotWidget-show-consent-details {
  color: #004a99 !important; }
  #CookiebotWidget .CookiebotWidget-show-consent-details svg {
    fill: #004a99 !important;
    position: relative;
    top: .0625rem; }

#CookiebotWidget #CookiebotWidget-buttons button {
  border-color: #004a99 !important;
  transition: all 0.3s ease-in-out; }
  #CookiebotWidget #CookiebotWidget-buttons button:hover, #CookiebotWidget #CookiebotWidget-buttons button:focus-visible {
    background-color: rgba(0, 74, 153, 0.05) !important;
    border-color: rgba(0, 74, 153, 0.75) !important;
    opacity: 1 !important; }

#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
  background-color: #004a99 !important; }
  #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change:hover, #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change:focus-visible {
    background-color: rgba(0, 74, 153, 0.75) !important; }

/* stylelint-enable */
*,
*::before,
*::after {
  box-sizing: border-box; }

*:focus {
  outline: none; }

html, body {
  margin: 0 auto;
  padding: 0;
  font-family: "Calima", "Arial", sans-serif;
  height: 100%; }

ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

a {
  text-decoration: none; }

b,
strong {
  font-weight: 700; }

::selection {
  background-color: #004a99;
  color: #ffffff; }

.h-100 {
  height: 100%; }

@media (min-width: 768px) {
  .h-md-auto {
    height: auto; } }

.mt-5 {
  margin-top: 3rem; }

.button {
  padding: 0.75em 1.25em;
  min-height: 3.125rem;
  line-height: 1.25em;
  font-size: 1rem;
  font-weight: 900;
  text-align: center;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #ffffff;
  border: 1px solid transparent;
  background-color: #d8d8d8;
  transition: all 0.3s ease-in-out; }
  .button svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: -0.5rem;
    margin-right: 0.5rem;
    vertical-align: middle; }
  @media (pointer: fine), (min-width: 1200px) {
    .button:hover {
      text-decoration: none;
      color: #ffffff; } }
  .button--wide {
    width: 100%;
    justify-content: center; }
  @media (min-width: 768px) {
    .button--large {
      padding-right: 50px; } }
  @media (min-width: 992px) {
    .button--large {
      padding-right: 75px; } }
  @media (min-width: 1200px) {
    .button--large {
      padding-right: 100px; } }
  .button--primary, .form__button {
    background-color: #004a99; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--primary:hover, .form__button:hover {
        background-color: #003166; } }
  .button--primary-ghost {
    color: #004a99;
    border: 2px solid #004a99;
    background-color: transparent; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--primary-ghost:hover {
        background-color: #003166;
        color: #ffffff; } }
  .button--secondary {
    background-color: #6ab023; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--secondary:hover {
        background-color: #50851b; } }
  .button--black {
    background-color: #000000; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--black:hover {
        background-color: #1a1a1a; } }
  .button--white {
    background-color: #ffffff;
    color: #004a99; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--white:hover {
        color: #004a99;
        background-color: #e0eff9; } }
  .button--white-ghost {
    color: #ffffff;
    border: 2px solid #ffffff;
    background-color: transparent; }
    @media (pointer: fine), (min-width: 1200px) {
      .button--white-ghost:hover {
        color: #004a99;
        background-color: #ffffff; } }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: "Calima", "Arial", sans-serif;
  font-weight: 900;
  color: #004a99; }

p {
  margin-top: 0; }

h1, .h1 {
  font-size: 1.875rem;
  line-height: 1.2em;
  margin-bottom: 0.5em;
  margin-top: .75em; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 2.625rem; } }
  @media (min-width: 992px) {
    h1, .h1 {
      font-size: 3.125rem; } }

h2, .h2 {
  font-size: 1.75rem;
  line-height: 1.25em;
  margin-bottom: 0.5em;
  margin-top: 1em; }
  @media (min-width: 768px) {
    h2, .h2 {
      font-size: 2.25rem; } }
  @media (min-width: 992px) {
    h2, .h2 {
      font-size: 2.5rem; } }

h3, .h3 {
  font-size: 1.625rem;
  line-height: 1.33em;
  margin-bottom: 0.5em;
  margin-top: 1.5em; }
  @media (min-width: 768px) {
    h3, .h3 {
      font-size: 1.875rem; } }

h4, .h4 {
  font-size: 1.5rem;
  line-height: 1.36em;
  margin-bottom: 0.5em;
  margin-top: 1.5em; }
  @media (min-width: 768px) {
    h4, .h4 {
      font-size: 1.75rem; } }

h5, .h5 {
  font-size: 1.3125rem;
  line-height: 1.42em;
  margin-bottom: 0.5em;
  margin-top: 1.5em; }
  @media (min-width: 768px) {
    h5, .h5 {
      font-size: 1.5rem; } }

h6, .h6 {
  font-size: 1.125rem;
  line-height: 1.55em;
  margin-bottom: 0.5em;
  margin-top: 1.5em; }
  @media (min-width: 768px) {
    h6, .h6 {
      font-size: 1.25rem; } }

strong {
  font-weight: 900; }

/* stylelint-disable */
.rich-text-area {
  -webkit-font-smoothing: antialiased;
  line-height: 1.937em; }
  .rich-text-area .intro {
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 1.8em; }
  .rich-text-area img {
    max-width: 100%; }
  .rich-text-area a:not(.button) {
    text-decoration: underline; }
  .rich-text-area ul {
    margin: 1.25rem 0; }
    .rich-text-area ul li {
      position: relative;
      padding-left: 20px;
      margin-bottom: 0; }
      .rich-text-area ul li::before {
        content: "";
        background: #004a99;
        width: 0.5em;
        height: 0.5em;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: .65em; }
    .rich-text-area ul ul {
      margin: 0; }
  .rich-text-area ol {
    counter-reset: my-counter;
    list-style: none;
    padding-left: 72px;
    margin: 2em 0 0;
    font-size: 24px;
    font-weight: 900;
    color: #004a99; }
    .rich-text-area ol li {
      margin: 0 0 3rem 0;
      counter-increment: my-counter;
      position: relative; }
      .rich-text-area ol li::before {
        content: "";
        position: absolute;
        left: calc(-56px - 17px);
        line-height: 56px;
        width: 56px;
        height: 56px;
        top: calc(-56px / 4);
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA1NiA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNy41ODk3IDBDMjEuNTk3NSAxOC41NzI2IDAgMjguMzA5OCAwIDI4LjMwOThDMCAyOC4zMDk4IDguNTE1MjQgMzAuOTc5NyAxNS4xOTM1IDM3Ljc2ODlDMjEuODY0NSA0NC41NTIgMjcuMTE0NiA1NiAyNy4xMTQ2IDU2QzI3LjExNDYgNTYgMzEuNjc2NSA0Ni41NDI5IDM4Ljc2NDYgMzkuMzQyM0M0NS44NDY1IDMyLjE0NTkgNTYgMjguMzc4NSA1NiAyOC4zNzg1QzU2IDI4LjM3ODUgNDMuMDU1MyAyMS44NjUyIDM3LjA1NyAxNS42OTc2QzMxLjA2MzggOS41Mjg5MiAyNy41ODk3IDAgMjcuNTg5NyAwWiIgZmlsbD0iIzZBQjAyMyIvPgo8L3N2Zz4K"); }
      .rich-text-area ol li::after {
        content: counter(my-counter);
        color: #ffffff;
        font-size: 1.25rem;
        font-weight: bold;
        position: absolute;
        left: calc(-32px - 17px);
        line-height: 32px;
        width: 32px;
        height: 32px;
        top: 0; }
  .rich-text-area--configurator {
    text-align: center; }
    .rich-text-area--configurator .button {
      margin: 0 .25rem .5rem; }

/* stylelint-enable */
.barba-bg {
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #ffffff;
  margin-left: 100%;
  opacity: 0;
  transition: margin 0s linear .3s, opacity .3s ease-in-out; }
  .barba-bg--visible {
    opacity: 1;
    margin-left: 0;
    transition: margin 0s linear, opacity .3s ease-in-out; }

.barba-wrapper {
  height: 100%; }

.barba-container {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%; }

input, select {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 0;
  color: #000000;
  font-family: "Calima", "Arial", sans-serif;
  height: 3.125rem;
  padding: 0 1rem;
  transition: all 0.3s ease-in-out;
  width: 100%; }
  input:focus, select:focus {
    outline: none;
    border: 1px solid #004a99; }

textarea {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 0;
  color: #000000;
  font-family: "Calima", "Arial", sans-serif;
  min-height: 9.375rem;
  padding: 8px 16px;
  resize: vertical;
  transition: border .3s ease-in-out;
  width: 100%; }
  textarea:focus {
    border: 1px solid #004a99;
    outline: none; }

select {
  appearance: none;
  background: #ffffff;
  color: #000000; }
  select::-ms-expand {
    display: none; }

.select-wrapper {
  position: relative;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .select-wrapper__select {
    height: 3.125rem;
    color: #000000;
    border: 1px solid #d8d8d8;
    font-weight: 500;
    padding: 0 20px; }
    .select-wrapper__select:focus {
      border: 1px solid #004a99; }
  .select-wrapper__dropdown-icon {
    position: absolute;
    right: 0.625rem;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    pointer-events: none; }
  .select-wrapper option {
    font-size: 18px;
    padding: 12px 0; }

.radio-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .radio-wrapper__input-wrapper {
    position: relative;
    width: 1.5625rem;
    height: 1.5625rem;
    margin-right: 0.5rem;
    float: left;
    cursor: pointer;
    flex-shrink: 0; }
    .radio-wrapper__input-wrapper::before {
      content: "";
      position: absolute;
      z-index: 1;
      border-radius: 50%;
      background-color: #ffffff;
      border: 1px solid #d8d8d8;
      display: block;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0; }
  .radio-wrapper__input-dot {
    position: absolute;
    left: 0.3125rem;
    top: 0.3125rem;
    z-index: 2;
    border-radius: 50%;
    background-color: #004a99;
    width: 0.9375rem;
    height: 0.9375rem;
    opacity: 0; }
  .radio-wrapper__input, .radio-wrapper input {
    line-height: 1;
    position: absolute;
    left: -62.4375rem; }
    .radio-wrapper__input:checked, .radio-wrapper input:checked {
      border: 1px solid #004a99; }
      .radio-wrapper__input:checked + .radio-wrapper__input-dot, .radio-wrapper input:checked + .radio-wrapper__input-dot {
        opacity: 1; }
  .radio-wrapper__label, .radio-wrapper label {
    font-size: 0.9375rem;
    cursor: pointer;
    font-weight: 400; }

.checkbox-wrapper {
  display: flex;
  align-items: start;
  margin-bottom: 0.5rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .checkbox-wrapper__input-wrapper {
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    line-height: 1.75rem; }
  .checkbox-wrapper__input-dot {
    width: 1.875rem;
    height: 1.875rem;
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    margin-right: 1.25rem;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    .checkbox-wrapper__input-dot svg {
      opacity: 0;
      fill: #6ab023;
      width: 70%;
      height: 70%; }
  .checkbox-wrapper__input, .checkbox-wrapper input {
    line-height: 1;
    position: absolute;
    left: -62.4375rem; }
    .checkbox-wrapper__input:checked + .checkbox-wrapper__input-dot svg, .checkbox-wrapper input:checked + .checkbox-wrapper__input-dot svg {
      opacity: 1; }
  .checkbox-wrapper__label, .checkbox-wrapper label {
    cursor: pointer;
    align-self: center;
    font-weight: 400;
    text-transform: none; }

.form-message {
  background-color: #d8d8d8;
  border-radius: 4px;
  color: #ffffff;
  padding: 12px 46px 12px 12px;
  margin: 2rem 0;
  position: relative;
  align-items: center;
  display: none;
  min-height: 3rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .form-message a {
    color: #ffffff;
    text-decoration: underline; }
  .form-message--active {
    display: flex; }
  .form-message--success {
    background-color: #55b890; }
    .form-message--success .form-message__icon--success {
      display: flex; }
  .form-message--error {
    background-color: #e50044; }
    .form-message--error .form-message__icon--error {
      display: flex; }
  .form-message__icon {
    position: absolute;
    right: 0.75rem;
    top: 0.5rem;
    align-items: center;
    justify-content: center;
    z-index: 2;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: none;
    border: 1px solid #ffffff; }
    .form-message__icon svg {
      width: 1rem;
      fill: #ffffff; }

.form {
  overflow: hidden;
  transition: all 0.3s ease-in-out; }
  .form__group-wrapper--half .form__group:last-of-type {
    margin-bottom: 1.5rem; }
  @media (min-width: 768px) {
    .form__group-wrapper--half {
      display: flex;
      margin: 0 -0.5rem; }
      .form__group-wrapper--half .form__group {
        margin: 0 0.5rem 1.5rem;
        width: 50%; } }
  .form__group {
    margin-bottom: 1.2em; }
  .form__invalid-message {
    color: #e50044;
    display: none;
    /* stylelint-disable */
    /* stylelint-enable */ }
    .form__invalid-message[show] {
      display: block; }
  .form__label {
    display: block;
    font-weight: 400;
    margin-bottom: .1rem; }
  .form--loading {
    opacity: .5; }
  @media (max-width: 767.98px) {
    .form legend {
      font-size: 1rem;
      margin-bottom: 12px; } }

/* stylelint-disable */
.grecaptcha-badge {
  display: none !important; }

/* stylelint-enable */
.container-extended {
  width: 100%;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 992px) {
    .container-extended {
      max-width: 1400px; } }

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  transition: all 0.3s ease-in-out; }
  .header--inverted {
    background: transparent; }
    .header--inverted::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 120%;
      opacity: .3;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%); }
    .header--inverted .header__main, .header--inverted .navigation__header {
      background: transparent; }
    .header--inverted .header__logo svg path {
      fill: #ffffff; }
    .header--inverted .header__logo-image {
      display: none; }
      .header--inverted .header__logo-image--inverted {
        display: block; }
  .header--scrolled {
    height: 6.5rem;
    margin-top: 0;
    top: -2.5rem;
    background: #ffffff; }
    @media (min-width: 768px) {
      .header--scrolled {
        height: 6.5rem;
        top: -2.5rem; } }
    @media (min-width: 992px) {
      .header--scrolled {
        height: 6.75rem;
        top: -2.5rem; } }
    @media (min-width: 1200px) {
      .header--scrolled {
        height: 6.75rem;
        top: -2.5rem; } }
    .header--scrolled .header__logo {
      padding: 1rem 0; }
      @media (min-width: 992px) {
        .header--scrolled .header__logo {
          padding: .5rem 0; } }
  .header--show-topbar {
    top: 0; }
    .header--show-topbar .header__logo {
      padding: 1rem 0; }
      @media (min-width: 992px) {
        .header--show-topbar .header__logo {
          padding: .1rem 0; } }
  .header__container {
    display: flex;
    flex-flow: row wrap;
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .header__top {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    height: 2.5rem;
    color: #ffffff;
    background-color: #6ab023;
    transition: all 0.3s ease-in-out, background 0.1s; }
    @media (min-width: 768px) {
      .header__top {
        height: 2.5rem; } }
    @media (min-width: 992px) {
      .header__top {
        height: 2.5rem; } }
    @media (min-width: 1200px) {
      .header__top {
        height: 2.5rem; } }
    @media (min-width: 1200px) {
      .header:not(.header--scrolled) .header__top--no-bg {
        background-color: transparent; } }
  .header__language-switch {
    flex: 0 0 auto;
    max-width: 100%; }
  .header__priority-navigation {
    flex: 1 1 auto;
    max-width: 100%; }
  .header__main, .navigation__header {
    display: flex;
    align-items: center;
    height: 4rem;
    background: #ffffff;
    transition: all 0.3s ease-in-out; }
    @media (min-width: 768px) {
      .header__main, .navigation__header {
        height: 4rem; } }
    @media (min-width: 992px) {
      .header__main, .navigation__header {
        height: 4.25rem; } }
    @media (min-width: 1200px) {
      .header__main, .navigation__header {
        height: 4.25rem; } }
    @media (min-width: 768px) {
      .header__main--no-bg {
        background-color: transparent; } }
  .header__main-container {
    flex-flow: row; }
  .header__brand {
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
    align-self: center; }
    @media (min-width: 1200px) {
      .header__brand {
        flex: 1 1 auto; } }
  .header__logo {
    display: block;
    flex: 0 0 auto;
    padding: 1rem 0;
    height: 4rem;
    transition: all 0.3s ease-in-out; }
    @media (min-width: 992px) {
      .header__logo {
        padding: .1rem 0; } }
    .header__logo--tennant {
      height: 3.5rem; }
      @media (min-width: 992px) {
        .header__logo--tennant {
          height: 2.5rem; } }
    .header__logo svg {
      height: 100%;
      transform: scale(1);
      transition: all 0.3s ease-in-out;
      width: auto; }
      @media (min-width: 992px) {
        .header--scrolled:not(.header--show-topbar) .header__logo svg {
          transform: scale(0.9); } }
  .header__buttons {
    order: 2;
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    @media (min-width: 1200px) {
      .header__buttons {
        order: 3;
        flex: 0 0 auto; } }
  .header__navigation {
    order: 3;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    @media (min-width: 1200px) {
      .header__navigation {
        order: 2;
        flex: 0 0 auto; } }
  .header__logo-image {
    height: 100%; }
    .header__logo-image--inverted {
      display: none; }
  .header__menu-toggle {
    display: flex;
    align-items: center;
    float: right;
    position: relative; }
    @media (min-width: 1200px) {
      .header__menu-toggle {
        display: none; } }

.header-spacer {
  width: 100%;
  padding-top: 4rem; }
  @media (min-width: 768px) {
    .header-spacer {
      padding-top: 4rem; } }
  @media (min-width: 992px) {
    .header-spacer {
      padding-top: 5rem; } }
  @media (min-width: 1200px) {
    .header-spacer {
      padding-top: 5rem; } }
  .header-spacer--has-topbar {
    padding-top: 6.5rem; }
    @media (min-width: 768px) {
      .header-spacer--has-topbar {
        padding-top: 6.5rem; } }
    @media (min-width: 992px) {
      .header-spacer--has-topbar {
        padding-top: 6.75rem; } }
    @media (min-width: 1200px) {
      .header-spacer--has-topbar {
        padding-top: 6.75rem; } }

.navigation {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  padding: 80px 16px 20px;
  height: 100%;
  width: 100%;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none; }
  @media (max-width: 1199.98px) {
    .navigation {
      background: #ffffff; } }
  @media (min-width: 1200px) {
    .navigation {
      display: inline-block;
      position: static;
      overflow: visible;
      margin: 0 0 0 1rem;
      padding: 0;
      width: auto;
      height: auto;
      opacity: 1;
      pointer-events: auto; } }
  .navigation__back {
    background-color: #6ab023;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
    padding: 0.625rem;
    min-height: auto;
    border-radius: 50%;
    line-height: 1.25rem;
    border-color: transparent; }
    .navigation__back--active {
      opacity: 1;
      pointer-events: auto; }
    .navigation__back svg {
      width: 1rem;
      height: 1rem;
      margin: 0;
      fill: #ffffff; }
  .navigation__close {
    margin-right: 1rem; }
  .navigation__container {
    display: flex;
    flex-flow: row wrap;
    padding: 0 1.5rem; }
  .navigation__header {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in-out; }
    @media (min-width: 1200px) {
      .navigation__header {
        display: none; } }
    .navigation__header--topbar-visible {
      top: 2.5rem; }
      @media (min-width: 768px) {
        .navigation__header--topbar-visible {
          top: 2.5rem; } }
      @media (min-width: 992px) {
        .navigation__header--topbar-visible {
          top: 2.5rem; } }
      @media (min-width: 1200px) {
        .navigation__header--topbar-visible {
          top: 2.5rem; } }
  .navigation__header-buttons {
    display: flex;
    margin-left: auto; }
  .navigation__list {
    width: 100%;
    /* stylelint-disable */
    /* stylelint-enable */ }
    @media (min-width: 1200px) {
      .navigation__list {
        width: auto; } }
    @media (min-width: 1200px) {
      .navigation__list > .navigation-item:last-child .navigation-item__link {
        padding-right: 0; } }
  .navigation--active {
    opacity: 1;
    pointer-events: auto;
    /* stylelint-disable */
    /* stylelint-enable */ }
    .navigation--active .navigation-item {
      opacity: 1;
      padding: 5px 0; }
  .navigation--topbar-visible {
    top: 2.5rem; }
    @media (min-width: 768px) {
      .navigation--topbar-visible {
        top: 2.5rem; } }
    @media (min-width: 992px) {
      .navigation--topbar-visible {
        top: 2.5rem; } }
    @media (min-width: 1200px) {
      .navigation--topbar-visible {
        top: 2.5rem; } }

.navigation-item {
  display: block;
  padding: 10px 0;
  position: relative;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  cursor: pointer; }
  @media (min-width: 1200px) {
    .navigation-item {
      display: inline-flex;
      padding: 0;
      position: static;
      opacity: 1; } }
  @media (min-width: 1200px) {
    .navigation-item--inverted .navigation-item__link {
      color: #ffffff; } }
  @media (min-width: 1200px) {
    .navigation-item--inverted .navigation-item__link--sub {
      color: #000000; } }
  @media (min-width: 1200px) {
    .navigation-item--inverted .navigation-item__sub-icon svg path {
      fill: #ffffff; } }
  .navigation-item--has-sub:hover > .navigation-item__link, .navigation-item--has-sub:focus > .navigation-item__link, .navigation-item--has-sub:focus-within > .navigation-item__link {
    color: #000000;
    /* stylelint-disable */
    /* stylelint-enable */ }
    .navigation-item--has-sub:hover > .navigation-item__link > .navigation-item__sub-icon--mobile svg path, .navigation-item--has-sub:focus > .navigation-item__link > .navigation-item__sub-icon--mobile svg path, .navigation-item--has-sub:focus-within > .navigation-item__link > .navigation-item__sub-icon--mobile svg path {
      fill: #000000; }
  @media (min-width: 1200px) {
    .navigation-item--has-sub:hover > .navigation-item__link--sub, .navigation-item--has-sub:focus > .navigation-item__link--sub, .navigation-item--has-sub:focus-within > .navigation-item__link--sub {
      color: #ffffff; } }
  .navigation-item--has-sub:hover .navigation-item__sub-icon--desktop svg path, .navigation-item--has-sub:focus .navigation-item__sub-icon--desktop svg path, .navigation-item--has-sub:focus-within .navigation-item__sub-icon--desktop svg path {
    fill: #000000; }
  @media (min-width: 1200px) {
    .navigation-item--has-sub:hover > .navigation-item__sub-list, .navigation-item--has-sub:focus > .navigation-item__sub-list, .navigation-item--has-sub:focus-within > .navigation-item__sub-list {
      transform: translateY(0);
      opacity: 1;
      pointer-events: visible;
      cursor: default; } }
  @media (min-width: 1200px) {
    .navigation-item--has-sub .navigation-item:hover > .navigation-item__link,
    .navigation-item--has-sub .navigation-item:focus > .navigation-item__link,
    .navigation-item--has-sub .navigation-item:focus-within > .navigation-item__link {
      opacity: 1; } }
  .navigation-item--has-sub .navigation-item--active .navigation-item__link {
    opacity: 1; }
  @media (min-width: 1200px) {
    .navigation-item--has-sub .navigation-item .navigation-item__link {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.625rem;
      padding: 0; } }
  @media (min-width: 1200px) {
    .navigation-item--has-sub .navigation-item .navigation-item__link--bold {
      font-weight: 900;
      font-size: 1.2rem; } }
  @media (min-width: 1600px) {
    .navigation-item--has-sub .navigation-item .navigation-item__link--bold {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    .navigation-item--has-sub .navigation-item .navigation-item__link--parent {
      font-weight: 500;
      font-size: 2rem;
      margin-bottom: .25rem;
      position: relative;
      width: fit-content; } }
  @media (max-width: 1199.98px) {
    .navigation-item--paginated.navigation-item--active > .navigation-item__sub-list {
      left: 0; } }
  @media (max-width: 1199.98px) {
    .navigation-item--paginated > .navigation-item__sub-list {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 100%;
      padding: 80px 16px 16px;
      width: 100%;
      height: 100%;
      background-color: #ffffff; } }
  @media (max-width: 1199.98px) {
    .navigation-item--paginated > .navigation-item__sub-list--has-topbar {
      top: 2.5rem; } }
  .navigation-item--paginated .navigation-item__link {
    text-align: left;
    display: flex;
    justify-content: space-between; }
    .navigation-item--paginated .navigation-item__link--parent {
      display: flex;
      justify-content: flex-start;
      padding: .25rem .25rem .75rem;
      font-size: 1.5rem;
      font-weight: 500;
      /* stylelint-disable */
      /* stylelint-enable */ }
      @media (min-width: 1200px) {
        .navigation-item--paginated .navigation-item__link--parent {
          font-size: 1rem;
          line-height: 1.5rem;
          padding: .25rem 1rem 1.25rem;
          color: #ffffff; } }
      @media (min-width: 1200px) {
        .navigation-item--paginated .navigation-item__link--parent:hover {
          color: #ffffff; } }
  @media (min-width: 1200px) {
    .navigation-item--parent {
      position: absolute;
      top: 0;
      left: 3rem;
      right: 3rem;
      transform: translateY(100%);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding: 0 0 1.25rem;
      width: 100%; } }
  @media (min-width: 1600px) {
    .navigation-item--parent {
      left: 6.875rem;
      right: 6.875rem; } }
  .navigation-item--first-sub {
    margin-bottom: 1.25rem;
    display: block;
    break-inside: avoid; }
  .navigation-item__link {
    display: inline-block;
    padding: 5px;
    width: 100%;
    line-height: 1.25em;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    color: #004a99;
    transition: all 0.3s ease-in-out; }
    @media (min-width: 1200px) {
      .navigation-item__link {
        font-weight: 900;
        padding: 1.5rem .5rem;
        font-size: 1.125rem;
        text-align: left; } }
    @media (min-width: 1400px) {
      .navigation-item__link {
        padding: 2rem 1rem; } }
    .navigation-item__link:hover {
      text-decoration: none; }
      .navigation-item__link:hover .navigation-item__hover-icon {
        opacity: 1;
        transform: translateX(0); }
    .navigation-item__link--current {
      color: #000000; }
      .navigation-item__link--current .navigation-item__sub-icon--desktop svg path {
        fill: #004a99; }
    .navigation-item__link--sub {
      font-size: 1.25rem;
      letter-spacing: .025em;
      color: #004a99; }
      @media (min-width: 1200px) {
        .navigation-item__link--sub {
          color: #ffffff; } }
      @media (max-width: 1199.98px) {
        .navigation-item__link--sub {
          color: #004a99; } }
      @media (min-width: 1200px) {
        .navigation-item__link--sub {
          padding: .25rem 1rem; } }
      .navigation-item__link--sub:hover {
        color: #000000; }
        @media (min-width: 1200px) {
          .navigation-item__link--sub:hover {
            color: #ffffff;
            text-decoration: underline; } }
  .navigation-item__link-navigation-handler {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    @media (min-width: 1200px) {
      .navigation-item__link-navigation-handler {
        display: none; } }
  .navigation-item__sub-list {
    margin-top: 0;
    padding: .5rem;
    transition: all 0.3s ease-in-out; }
    @media (min-width: 1200px) {
      .navigation-item__sub-list {
        column-count: 4;
        position: absolute;
        z-index: 9;
        top: 100%;
        left: 0;
        padding: 10rem 3rem 3rem;
        color: #000000;
        background-color: #004a99;
        box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.2);
        width: 100%;
        transform: translateY(-20px);
        opacity: 0;
        pointer-events: none; } }
    @media (min-width: 1600px) {
      .navigation-item__sub-list {
        padding: 10rem 6.875rem 3rem; } }
    .navigation-item__sub-list--sub {
      left: 100%;
      top: 0;
      position: static;
      padding: 0;
      opacity: 1;
      box-shadow: none;
      transform: none;
      display: flex;
      flex-direction: column; }
    .navigation-item__sub-list .navigation-item__list-item {
      display: block;
      width: 100%; }
    .navigation-item__sub-list .navigation-item__item {
      font-weight: 500;
      line-height: 1.5em;
      font-size: 1rem; }
  .navigation-item__sub-icon svg {
    margin-left: 8px;
    width: 20px;
    height: 20px; }
  .navigation-item__sub-icon svg path {
    fill: #004a99;
    transition: all 0.3s ease-in-out; }
  @media (min-width: 1200px) {
    .navigation-item__sub-icon--mobile {
      display: none; } }
  @media (max-width: 1199.98px) {
    .navigation-item__sub-icon--desktop {
      display: none; } }
  .navigation-item__sub-icon--desktop svg {
    width: 10px;
    height: 16px; }
  .navigation-item__hover-icon {
    display: none;
    margin-left: .75rem;
    opacity: 0;
    transform: translateX(-1rem);
    transition: all 0.3s ease-in-out; }
    @media (min-width: 1200px) {
      .navigation-item__hover-icon {
        display: block; } }
    .navigation-item__hover-icon svg path {
      fill: #6ab023; }
    @media (min-width: 1200px) {
      .navigation-item__hover-icon--parent {
        position: absolute;
        top: 0;
        left: 100%;
        transform: translateX(50%);
        opacity: 1; } }
    .navigation-item__hover-icon--parent svg path {
      fill: #ffffff; }

@media (min-width: 1200px) {
  .priority-navigation--inverted .priority-navigation__link {
    color: #ffffff; } }

@media (min-width: 1200px) {
  .priority-navigation--inverted .priority-navigation__link svg path {
    fill: #ffffff; } }

@media (min-width: 1200px) {
  .priority-navigation--inverted .priority-navigation__icon svg path {
    fill: #ffffff; } }

.priority-navigation__list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow-x: auto; }
  @media (min-width: 768px) {
    .priority-navigation__list {
      height: 2.5rem; } }
  @media (min-width: 992px) {
    .priority-navigation__list {
      height: 2.5rem; } }
  @media (min-width: 1200px) {
    .priority-navigation__list {
      height: 2.5rem; } }
  @media (min-width: 1200px) {
    .priority-navigation__list {
      overflow-x: visible;
      justify-content: flex-end; } }
  @media (max-width: 1599.98px) {
    .priority-navigation__list::before {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      opacity: 0;
      top: 0;
      left: 10px;
      width: 6rem;
      height: 2.5rem;
      background: linear-gradient(135deg, #6ab023 0%, rgba(106, 176, 35, 0) 100%);
      pointer-events: none;
      transition: all 0.3s ease-in-out; } }
  @media (max-width: 1599.98px) {
    .priority-navigation__list::after {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      opacity: 0;
      top: 0;
      right: 10px;
      width: 6rem;
      height: 2.5rem;
      background: linear-gradient(270deg, #6ab023 0%, rgba(106, 176, 35, 0) 100%);
      pointer-events: none;
      transition: all 0.3s ease-in-out; } }
  .priority-navigation__list--scrolled-left::before {
    opacity: 1; }
  .priority-navigation__list--scrolled-right::after {
    opacity: 1; }

.priority-navigation__search {
  align-self: center;
  /*stylelint-disable*/
  /*stylelint-enable*/ }
  @media (max-width: 991px) {
    .priority-navigation__search.active {
      width: 200px;
      right: 40px;
      /*stylelint-disable*/
      /*stylelint-enable*/ }
      .priority-navigation__search.active::before {
        right: 30px; }
      .priority-navigation__search.active input::placeholder {
        color: #6ab023; }
      .priority-navigation__search.active input[type=text] {
        width: auto; } }
  .priority-navigation__search #submit {
    background: url("/images/icons/search-icon.svg") no-repeat;
    position: absolute;
    top: 4px;
    border: none;
    right: 0;
    cursor: pointer;
    width: 10px;
    height: 17px; }
  .priority-navigation__search input::placeholder {
    color: #6ab023; }
  .priority-navigation__search input[type=text] {
    border: none;
    height: unset;
    color: #6ab023;
    font-size: 0.875rem;
    line-height: 24px;
    padding: 2px 18px 0;
    background: #d2e7bd;
    border-radius: 25px;
    max-width: 230px; }

.priority-navigation__list-item {
  position: relative;
  flex: none;
  margin: 0 .75rem;
  white-space: nowrap; }
  .priority-navigation__list-item:hover > .priority-navigation__list-item--sub, .priority-navigation__list-item:focus > .priority-navigation__list-item--sub, .priority-navigation__list-item:focus-within > .priority-navigation__list-item--sub {
    transform: translateY(0);
    opacity: 1;
    pointer-events: visible; }
  .priority-navigation__list-item:hover > .priority-navigation__link, .priority-navigation__list-item:focus > .priority-navigation__link, .priority-navigation__list-item:focus-within > .priority-navigation__link {
    color: #000000;
    text-decoration: none;
    transition: all 0.3s ease-in-out; }
  .priority-navigation__list-item:hover .priority-navigation__link .priority-navigation__sub-icon svg path, .priority-navigation__list-item:focus .priority-navigation__link .priority-navigation__sub-icon svg path, .priority-navigation__list-item:focus-within .priority-navigation__link .priority-navigation__sub-icon svg path {
    fill: #000000;
    transition: all 0.3s ease-in-out; }
  .priority-navigation__list-item:hover .priority-navigation__link .priority-navigation__icon svg path, .priority-navigation__list-item:focus .priority-navigation__link .priority-navigation__icon svg path, .priority-navigation__list-item:focus-within .priority-navigation__link .priority-navigation__icon svg path {
    fill: #004a99; }
  @media (min-width: 1200px) {
    .priority-navigation__list-item {
      margin: 0 0.75rem; } }
  @media (max-width: 1199.98px) {
    .priority-navigation__list-item:first-child {
      margin-left: 0; } }
  @media (min-width: 1200px) {
    .priority-navigation__list-item:last-child {
      margin-right: 0; } }
  .priority-navigation__list-item--sub {
    position: absolute;
    z-index: 9;
    top: 100%;
    left: 0;
    margin-left: 0;
    padding: 0.5rem 1rem;
    color: #000000;
    background: #ffffff;
    box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-20px);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out; }
    @media (max-width: 1199.98px) {
      .priority-navigation__list-item--sub {
        display: none; } }
    .priority-navigation__list-item--sub .priority-navigation__list-item {
      margin: 0; }
    .priority-navigation__list-item--sub .priority-navigation__link {
      color: #004a99;
      transition: all 0.3s ease-in-out; }

.priority-navigation__link {
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  font-size: .875rem;
  letter-spacing: .025em;
  color: #ffffff;
  height: 2.5rem; }
  @media (min-width: 768px) {
    .priority-navigation__link {
      height: 2.5rem; } }
  @media (min-width: 992px) {
    .priority-navigation__link {
      height: 2.5rem; } }
  @media (min-width: 1200px) {
    .priority-navigation__link {
      height: 2.5rem; } }

.priority-navigation__sub-icon {
  margin-left: 0.375rem; }
  @media (max-width: 1199.98px) {
    .priority-navigation__sub-icon {
      display: none; } }
  .priority-navigation__sub-icon svg path {
    fill: #ffffff; }

.priority-navigation__icon svg {
  margin-right: .5rem;
  width: 16px;
  height: 16px;
  vertical-align: sub; }

.priority-navigation__icon svg path {
  transition: all 0.3s ease-in-out;
  fill: #6ab023; }

.priority-navigation__show-mobile {
  display: inline-block; }
  @media (min-width: 768px) {
    .priority-navigation__show-mobile {
      display: none; } }

.hero {
  position: relative;
  margin-top: -20px;
  height: 400px;
  text-align: center; }
  @media (min-width: 768px) {
    .hero {
      height: 600px; } }
  .hero::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 20%, rgba(0, 19, 51, 0.7) 100%); }
  .hero__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center; }
  .hero__video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; }
  .hero__video {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    min-width: 100%;
    min-height: 100%; }
    @media (min-width: 768px) {
      .hero__video {
        display: block; } }
    .hero__video--fullscreen {
      display: block;
      position: static;
      transform: none;
      min-width: unset;
      min-height: unset; }
    .hero__video--no-fullscreen {
      position: static;
      transform: none;
      min-width: unset;
      min-height: unset; }
  .hero__content {
    position: relative;
    z-index: 1;
    margin: 0 auto;
    padding: 0 10px;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    text-align: left; }
  .hero__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%; }
  .hero__title {
    color: #ffffff; }
  .hero__button {
    margin-right: 1em; }
  .hero__play-video-link {
    display: inline-block;
    margin-top: .8em;
    color: #ffffff; }
    .hero__play-video-link svg {
      margin-right: .5em; }
    .hero__play-video-link svg path {
      fill: #ffffff; }
    .hero__play-video-link:hover {
      text-decoration: none;
      color: #6ab023;
      transition: all 0.3s ease-in-out; }
      .hero__play-video-link:hover svg path {
        fill: #6ab023;
        transition: all 0.3s ease-in-out; }
  .hero__diamond-shape {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%; }
    .hero__diamond-shape svg {
      float: right;
      height: 200%;
      width: auto;
      transform: translateX(50%) scale(1.25); }
  .hero__diamond-shape1 {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    overflow: hidden; }
    .hero__diamond-shape1 svg path {
      fill: rgba(106, 176, 35, 0.4); }
  .hero__diamond-shape2 {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%; }
    .hero__diamond-shape2 svg path {
      fill: rgba(106, 176, 35, 0.3); }
  .hero--compact {
    height: 300px; }
    @media (min-width: 768px) {
      .hero--compact {
        height: 400px; } }
    .hero--compact::after {
      display: none; }

.product-navigation {
  /* stylelint-disable */
  /* stylelint-enable */ }
  .product-navigation__list {
    display: block;
    flex-direction: row;
    flex: auto;
    vertical-align: center; }
    @media (min-width: 992px) {
      .product-navigation__list {
        justify-content: flex-end;
        list-style: none;
        display: flex;
        padding: 0;
        width: auto;
        height: 60%;
        min-height: auto; } }
    .product-navigation__list--sub {
      display: none;
      padding: 8px; }
      @media (min-width: 992px) {
        .product-navigation__list--sub {
          position: absolute;
          top: 100%;
          left: -8px;
          height: auto;
          border: 1px solid #d8d8d8;
          border-top: none;
          background: #ffffff;
          min-width: 220px; } }
      .product-navigation__list--sub .product-navigation__list-item {
        display: block;
        width: 100%;
        margin: 0;
        padding: 5px 0; }
      .product-navigation__list--sub .product-navigation__item {
        font-size: 0.875rem; }
        @media (min-width: 992px) {
          .product-navigation__list--sub .product-navigation__item {
            color: #004a99; } }
    @media (min-width: 992px) {
      .product-navigation__last-item .product-navigation__list {
        right: -8px;
        left: auto; } }
  .product-navigation__list-item {
    transition: all 0.3s 0.15s ease-in-out;
    opacity: 0;
    flex-wrap: wrap;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 25px 0 10px;
    width: auto;
    text-align: center;
    display: block; }
    @media (min-width: 992px) {
      .product-navigation__list-item {
        flex: unset;
        align-items: center;
        padding: 20px 0;
        margin: 0;
        opacity: 1;
        width: auto;
        float: left;
        height: 100%; } }
    @media (min-width: 1200px) {
      .product-navigation__list-item {
        margin: 0 8px; } }
    .product-navigation__list-item svg {
      margin-left: 1px; }
    .product-navigation__list-item svg path {
      fill: #004a99;
      transition: all 0.3s ease-in-out; }
    .product-navigation__list-item:hover svg path {
      fill: #000000; }
    .product-navigation__list-item:hover > .product-navigation__item {
      text-decoration: underline;
      color: #000000; }
    .product-navigation__list-item--has-sub {
      width: 100%;
      /*stylelint-disable*/
      /*stylelint-enable*/ }
      @media (min-width: 992px) {
        .product-navigation__list-item--has-sub {
          width: auto; } }
      @media (min-width: 992px) {
        .product-navigation__list-item--has-sub:hover .product-navigation__list--sub {
          display: block; } }
  .product-navigation__list-item-level-2 {
    display: flex; }
    .product-navigation__list-item-level-2 svg {
      transform: rotate(270deg); }
  .product-navigation__item {
    display: inline-block;
    font-size: 0.9375rem;
    font-weight: 900;
    line-height: 1.25em;
    color: #004a99;
    padding: 5px 0 5px 5px;
    white-space: nowrap;
    transition: all 0.3s ease-in-out; }
    @media (min-width: 768px) {
      .product-navigation__item {
        font-size: 0.8125rem; } }
    @media (min-width: 992px) {
      .product-navigation__item {
        text-align: left; } }
    @media (min-width: 1200px) {
      .product-navigation__item {
        font-size: 0.9375rem; } }
    .product-navigation__item--active {
      text-decoration: underline; }
  .product-navigation--active .product-navigation__list-item {
    opacity: 1;
    padding: 5px 0; }
  .product-navigation__list-item--has-sub .product-navigation__list--sub .product-navigation__list-item--has-sub:hover > .product-navigation__list--sub {
    display: block; }
  .product-navigation__list-item--has-sub .product-navigation__list--sub .product-navigation__list--sub {
    display: none; }
    @media (min-width: 992px) {
      .product-navigation__list-item--has-sub .product-navigation__list--sub .product-navigation__list--sub {
        display: block;
        border: 1px solid #d8d8d8; } }
    .product-navigation__list-item--has-sub .product-navigation__list--sub .product-navigation__list--sub .product-navigation__item {
      font-size: 0.75rem;
      line-height: 1.1em;
      color: #000000; }
    @media (min-width: 992px) {
      .product-navigation__list-item--has-sub .product-navigation__list--sub .product-navigation__list--sub {
        display: none;
        position: absolute;
        top: -8px;
        left: 100%;
        width: 100%;
        background-color: #ffffff; }
        .product-navigation__list-item--has-sub .product-navigation__list--sub .product-navigation__list--sub.product-navigation--left {
          left: calc(-100% + -15px); } }
  .product-navigation .product-navigation--opened > .product-navigation__list--sub {
    display: block; }

.main {
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 50px;
  transition: all 0.3s ease-in-out;
  flex: 1 1 100%; }
  .main--show-overflow {
    overflow: visible; }
  @media (min-width: 992px) {
    .main {
      padding-bottom: 120px; } }

.breadcrumb {
  margin: 20px 0 10px;
  font-size: 14px;
  font-weight: 900;
  color: #004a99; }
  @media (min-width: 768px) {
    .breadcrumb {
      display: flex; } }
  .breadcrumb svg {
    height: 90%;
    width: 4px;
    margin: 0 .25em; }
  .breadcrumb svg path {
    fill: #787878; }
  .breadcrumb__list-item {
    display: inline-block; }
    @media (min-width: 768px) {
      .breadcrumb__list-item {
        display: unset; } }
  .breadcrumb__link {
    font-weight: 400;
    color: #787878; }

.page-submenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90;
  background: #ffffff;
  transition: all .3s ease-in-out;
  transform: translateX(-100%);
  margin: 50px 0 0;
  padding: 60px 30px 0; }
  @media (min-width: 768px) {
    .page-submenu {
      position: static;
      transform: none;
      margin: 0 0 1.5rem;
      padding: 0; } }
  .page-submenu--expanded {
    transform: translateX(0);
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.5); }
  .page-submenu__title {
    font-size: 1.5rem;
    color: #004a99;
    margin-top: 2rem; }
  .page-submenu__col {
    margin-bottom: 20px; }
  .page-submenu__item {
    background-color: #004a99;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 1rem 2rem 1rem 1rem;
    position: relative; }
    @media (min-width: 768px) {
      .page-submenu__item {
        padding: 1.25rem 2.5rem 1.25rem 1.25rem; } }
    .page-submenu__item:hover {
      color: #ffffff;
      text-decoration: none; }
      @media (min-width: 1200px) {
        .page-submenu__item:hover .page-submenu__hover-icon {
          transform: translate(50%, -50%) scale(1); } }
  .page-submenu__hover-icon {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translate(50%, -50%) scale(0);
    transition: all .3s ease-in-out; }
    .page-submenu__hover-icon svg {
      width: 30px;
      height: 30px;
      /* stylelint-disable */
      /* stylelint-enable */ }
      .page-submenu__hover-icon svg path {
        fill: #6ab023; }
  .page-submenu__arrow {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%); }
    @media (min-width: 768px) {
      .page-submenu__arrow {
        right: 1.25rem; } }
  .page-submenu__results-count {
    margin: 2.5rem 0 0; }

.page-nav-menu {
  margin-top: 38px;
  padding: 40px 30px 30px;
  background: #e0eff9; }
  .page-nav-menu__list-item {
    margin-bottom: 0.8em;
    padding-left: 1.625em;
    font-weight: 900;
    color: #004a99; }
    .page-nav-menu__list-item svg {
      height: 1.125em;
      width: 1.125em;
      margin-left: -1.625em;
      margin-right: 0.2em; }
    .page-nav-menu__list-item svg path {
      fill: #6ab023; }

.site {
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 100%;
  align-content: space-between; }

.footer {
  background-color: #ffffff;
  color: #000000;
  position: relative;
  z-index: 15;
  flex: 1 1 100%; }
  @media (min-width: 992px) {
    .footer::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background-color: #e0eff9; }
    .footer::after {
      content: "";
      display: block;
      position: absolute;
      top: 116px;
      right: 0;
      bottom: 0;
      width: 50%;
      background-color: #2495c5; } }
  .footer__left {
    position: relative;
    z-index: 1;
    padding: 45px 10px;
    margin: 0 -10px;
    height: 100%;
    border-top-right-radius: 100px;
    background: #e0eff9; }
    @media (min-width: 768px) {
      .footer__left {
        padding: 90px 90px 20px 0;
        margin-right: -10px; } }
  .footer__right {
    position: relative;
    z-index: 1;
    margin: 0 -10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: center; }
    @media (min-width: 768px) {
      .footer__right {
        margin-left: -10px; } }
  .footer__contact-option {
    display: flex;
    align-items: center;
    font-weight: 800;
    margin-bottom: 70px; }
    .footer__contact-option svg {
      margin-right: 24px; }
  .footer__contact-option-label {
    display: inline-block; }
  .footer__pay-off {
    padding: 30px 10px 20px; }
    .footer__pay-off svg {
      max-width: 100%; }
  .footer__partner {
    padding: 60px 10px;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .footer__partner-label {
    display: inline-block;
    padding-top: 26px;
    font-weight: 900; }
  .footer__credits {
    flex: auto;
    padding-bottom: 45px;
    color: #ffffff;
    background: #2495c5; }
  .footer__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .footer__list-item {
    width: 100%;
    flex: 0 0 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 100;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    margin-bottom: 0.5em; }
    @media (min-width: 768px) {
      .footer__list-item {
        margin-bottom: 0;
        width: auto;
        flex: 0 0 auto;
        text-align: left;
        padding-right: 10px; }
        .footer__list-item::after {
          content: "|";
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px; }
        .footer__list-item:last-child::after {
          content: none; } }
  .footer__link {
    color: inherit; }
    .footer__link:hover {
      text-decoration: underline; }

.searchform {
  background: #eaeaea;
  padding: 25px 10px;
  /*stylelint-disable*/
  /*stylelint-enable*/ }
  .searchform input[type=submit] {
    background: #6ab023;
    color: #ffffff; }

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin: 2rem 0; }
  .video-wrapper__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.configurator {
  padding-top: 60px; }

.quicklinks {
  position: relative;
  z-index: 1;
  margin-top: calc(-2em);
  margin-bottom: 2em;
  background: #004a99; }
  @media (min-width: 768px) {
    .quicklinks {
      margin-top: calc(-1em - 100px);
      margin-bottom: calc(-2em - 200px); } }
  .quicklinks__list-item {
    position: relative;
    border-bottom: 1px solid rgba(216, 216, 216, 0.3); }
    .quicklinks__list-item:last-child {
      border-bottom: none; }
    .quicklinks__list-item:hover .quicklinks__hover-icon {
      transform: translateY(-50%) scale(1); }
  .quicklinks__hover-icon {
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%) scale(0);
    transition: all 0.3s ease-in-out; }
    .quicklinks__hover-icon svg {
      width: 40px;
      height: 40px; }
    .quicklinks__hover-icon svg path {
      fill: #6ab023; }
  .quicklinks__link {
    display: inline-block;
    padding: 46px 35px;
    width: 100%;
    font-weight: 900;
    color: #ffffff; }
    @media (min-width: 992px) {
      .quicklinks__link {
        font-size: 1.5rem; } }
    .quicklinks__link:hover {
      text-decoration: none;
      color: #ffffff; }
    .quicklinks__link svg {
      display: inline-block;
      margin-top: 0.15em;
      float: right; }
      @media (min-width: 768px) {
        .quicklinks__link svg {
          margin-top: .4em; } }
    .quicklinks__link svg path {
      fill: #ffffff; }

.featured-page {
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-bottom: 1em;
  height: 280px;
  background-size: cover;
  background-position: center;
  transition: all 0.3s ease-in-out;
  /*stylelint-disable*/
  /*stylelint-enable*/ }
  @media (min-width: 768px) {
    .featured-page {
      margin-bottom: 140px; } }
  .featured-page:hover {
    z-index: 1;
    text-decoration: none;
    transform: scale(1.15); }
    .featured-page:hover::after {
      opacity: 0; }
    .featured-page:hover .featured-page__title {
      font-size: calc(1.5rem / 1.15);
      transform: translateY(100%);
      background: #004a99; }
    .featured-page:hover .featured-page__hover-icon {
      transform: translateX(-50%) scale(1); }
  .featured-page::after {
    content: "";
    display: block;
    position: absolute;
    top: 116px;
    bottom: 0;
    left: 0;
    width: 100%;
    background: -webkit-gradient(linear, right top, left top, color-stop(20%, rgba(0, 0, 0, 0)), to(rgba(0, 19, 51, 0.7)));
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 19, 51, 0.6) 100%);
    transition: all 0.3s ease-in-out; }
  .featured-page__title {
    position: relative;
    z-index: 1;
    margin: 0;
    padding: 30px 0;
    width: 100%;
    text-align: center;
    color: #ffffff;
    transition: all 0.3s ease-in-out; }
  .featured-page__hover-icon {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%) scale(0);
    transition: all 0.3s ease-in-out; }
    .featured-page__hover-icon svg {
      width: 40px;
      height: 40px; }
    .featured-page__hover-icon svg path {
      fill: #6ab023; }

.usp-list {
  margin-bottom: 1em; }
  .usp-list__list-item {
    position: relative;
    padding-left: 1.5em;
    margin-bottom: .5em;
    color: #000000; }
    .usp-list__list-item::before {
      content: "";
      position: absolute;
      top: .125em;
      left: 0;
      width: 1em;
      height: 1em;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1Ljc1IDhDMTUuNzUgNi42MjUgMTUuMzc1IDUuMzEyNSAxNC42ODc1IDQuMTI1QzE0IDIuOTM3NSAxMy4wNjI1IDIgMTEuODc1IDEuMzEyNUMxMC42ODc1IDAuNjI1IDkuMzc1IDAuMjUgOCAwLjI1QzYuNTkzNzUgMC4yNSA1LjMxMjUgMC42MjUgNC4xMjUgMS4zMTI1QzIuOTM3NSAyIDEuOTY4NzUgMi45Mzc1IDEuMjgxMjUgNC4xMjVDMC41OTM3NSA1LjMxMjUgMC4yNSA2LjYyNSAwLjI1IDhDMC4yNSA5LjQwNjI1IDAuNTkzNzUgMTAuNjg3NSAxLjI4MTI1IDExLjg3NUMxLjk2ODc1IDEzLjA2MjUgMi45Mzc1IDE0LjAzMTIgNC4xMjUgMTQuNzE4OEM1LjMxMjUgMTUuNDA2MiA2LjU5Mzc1IDE1Ljc1IDggMTUuNzVDOS4zNzUgMTUuNzUgMTAuNjg3NSAxNS40MDYyIDExLjg3NSAxNC43MTg4QzEzLjA2MjUgMTQuMDMxMiAxNCAxMy4wNjI1IDE0LjY4NzUgMTEuODc1QzE1LjM3NSAxMC42ODc1IDE1Ljc1IDkuNDA2MjUgMTUuNzUgOFpNNy4wOTM3NSAxMi4wOTM4QzcgMTIuMjE4OCA2Ljg3NSAxMi4yNSA2Ljc1IDEyLjI1QzYuNTkzNzUgMTIuMjUgNi40Njg3NSAxMi4yMTg4IDYuNDA2MjUgMTIuMDkzOEwzLjE1NjI1IDguODQzNzVDMy4wMzEyNSA4Ljc4MTI1IDMgOC42NTYyNSAzIDguNUMzIDguMzc1IDMuMDMxMjUgOC4yNSAzLjE1NjI1IDguMTU2MjVMMy44NDM3NSA3LjQzNzVDMy45Mzc1IDcuMzQzNzUgNC4wNjI1IDcuMjgxMjUgNC4xODc1IDcuMjgxMjVDNC4zMTI1IDcuMjgxMjUgNC40Mzc1IDcuMzQzNzUgNC41NjI1IDcuNDM3NUw2Ljc1IDkuNjI1TDExLjQzNzUgNC45Mzc1QzExLjUzMTIgNC44NDM3NSAxMS42NTYyIDQuNzgxMjUgMTEuNzgxMiA0Ljc4MTI1QzExLjkwNjIgNC43ODEyNSAxMi4wMzEyIDQuODQzNzUgMTIuMTU2MiA0LjkzNzVMMTIuODQzOCA1LjY1NjI1QzEyLjkzNzUgNS43NSAxMyA1Ljg3NSAxMyA2QzEzIDYuMTU2MjUgMTIuOTM3NSA2LjI4MTI1IDEyLjg0MzggNi4zNDM3NUw3LjA5Mzc1IDEyLjA5MzhaIiBmaWxsPSIjNkFCMDIzIi8+Cjwvc3ZnPgo="); }
  .usp-list--white .usp-list__list-item {
    color: #ffffff; }
    .usp-list--white .usp-list__list-item::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1Ljc1IDhDMTUuNzUgNi42MjUgMTUuMzc1IDUuMzEyNSAxNC42ODc1IDQuMTI1QzE0IDIuOTM3NSAxMy4wNjI1IDIgMTEuODc1IDEuMzEyNUMxMC42ODc1IDAuNjI1IDkuMzc1IDAuMjUgOCAwLjI1QzYuNTkzNzUgMC4yNSA1LjMxMjUgMC42MjUgNC4xMjUgMS4zMTI1QzIuOTM3NSAyIDEuOTY4NzUgMi45Mzc1IDEuMjgxMjUgNC4xMjVDMC41OTM3NSA1LjMxMjUgMC4yNSA2LjYyNSAwLjI1IDhDMC4yNSA5LjQwNjI1IDAuNTkzNzUgMTAuNjg3NSAxLjI4MTI1IDExLjg3NUMxLjk2ODc1IDEzLjA2MjUgMi45Mzc1IDE0LjAzMTIgNC4xMjUgMTQuNzE4OEM1LjMxMjUgMTUuNDA2MiA2LjU5Mzc1IDE1Ljc1IDggMTUuNzVDOS4zNzUgMTUuNzUgMTAuNjg3NSAxNS40MDYyIDExLjg3NSAxNC43MTg4QzEzLjA2MjUgMTQuMDMxMiAxNCAxMy4wNjI1IDE0LjY4NzUgMTEuODc1QzE1LjM3NSAxMC42ODc1IDE1Ljc1IDkuNDA2MjUgMTUuNzUgOFpNNy4wOTM3NSAxMi4wOTM4QzcgMTIuMjE4OCA2Ljg3NSAxMi4yNSA2Ljc1IDEyLjI1QzYuNTkzNzUgMTIuMjUgNi40Njg3NSAxMi4yMTg4IDYuNDA2MjUgMTIuMDkzOEwzLjE1NjI1IDguODQzNzVDMy4wMzEyNSA4Ljc4MTI1IDMgOC42NTYyNSAzIDguNUMzIDguMzc1IDMuMDMxMjUgOC4yNSAzLjE1NjI1IDguMTU2MjVMMy44NDM3NSA3LjQzNzVDMy45Mzc1IDcuMzQzNzUgNC4wNjI1IDcuMjgxMjUgNC4xODc1IDcuMjgxMjVDNC4zMTI1IDcuMjgxMjUgNC40Mzc1IDcuMzQzNzUgNC41NjI1IDcuNDM3NUw2Ljc1IDkuNjI1TDExLjQzNzUgNC45Mzc1QzExLjUzMTIgNC44NDM3NSAxMS42NTYyIDQuNzgxMjUgMTEuNzgxMiA0Ljc4MTI1QzExLjkwNjIgNC43ODEyNSAxMi4wMzEyIDQuODQzNzUgMTIuMTU2MiA0LjkzNzVMMTIuODQzOCA1LjY1NjI1QzEyLjkzNzUgNS43NSAxMyA1Ljg3NSAxMyA2QzEzIDYuMTU2MjUgMTIuOTM3NSA2LjI4MTI1IDEyLjg0MzggNi4zNDM3NUw3LjA5Mzc1IDEyLjA5MzhaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K"); }

.usp-block {
  margin-top: 2em;
  padding: 36px 30px;
  color: #ffffff;
  background-color: #004a99; }
  .usp-block__title {
    margin-top: 0;
    color: #ffffff; }

.title-dotted__icon {
  vertical-align: text-top; }
  .title-dotted__icon svg {
    height: 0.35em;
    width: 0.35em; }
  .title-dotted__icon svg path {
    fill: #6ab023; }

.advice-block {
  padding: 20px 30px;
  background-color: #e0eff9; }
  .advice-block__image-wrapper {
    display: flex;
    align-items: center;
    height: 100%; }
  .advice-block__image {
    min-width: 1px;
    min-height: 1px;
    max-width: 100%; }
  .advice-block__title {
    margin-top: .5em; }
  .advice-block__text {
    margin-top: .5em; }
  .advice-block__contact-options {
    margin-top: 1em;
    margin-bottom: .5em; }
  .advice-block__contact-option {
    display: inline-block;
    margin-right: 2em;
    margin-bottom: .5em;
    width: 100%; }
    @media (min-width: 768px) {
      .advice-block__contact-option {
        margin-bottom: 0;
        width: auto; } }
    .advice-block__contact-option svg {
      vertical-align: baseline; }
    .advice-block__contact-option--name {
      font-weight: 900; }
    .advice-block__contact-option--whatsapp svg path {
      fill: #6ab023; }
    .advice-block__contact-option--phone svg path, .advice-block__contact-option--mail svg path {
      fill: #004a99; }
    .advice-block__contact-option--phone svg {
      vertical-align: text-top; }
  .advice-block__link {
    color: #000000; }
  .advice-block__button-wrapper {
    display: flex;
    align-items: center;
    height: 100%; }

.page-intro__title {
  margin-bottom: .25em; }

.page-intro__results-count, .page-intro__introduction {
  margin-bottom: 1em; }

.accordion {
  font-family: sans-serif;
  padding-bottom: 20px; }
  .accordion__panel {
    background: #ffffff; }
  .accordion__panel-title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px 20px;
    margin: 0;
    line-height: 2.5rem;
    color: #004a99;
    border-radius: 3px;
    background: #e0eff9; }
    .accordion__panel-title:hover {
      text-decoration: underline; }
    .accordion__panel-title--active {
      background: #ffffff; }
  .accordion__panel-content {
    overflow: hidden;
    transition: all 0.3s ease-in-out; }
  .accordion__panel-content-inner {
    padding: 10px 60px 10px 20px;
    /* stylelint-disable */
    /* stylelint-enable */ }
    .accordion__panel-content-inner .rich-text-area ul {
      margin: 0 0 1rem; }
  .accordion__panel-status-icon {
    position: relative;
    display: inline-block;
    width: 40px;
    min-width: 40px;
    height: 40px;
    font-size: 0;
    color: transparent;
    border-radius: 50%;
    background: #ffffff;
    transition: all 0.3s ease-in-out; }
    .accordion__panel-status-icon::before, .accordion__panel-status-icon::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform-origin: 0 0;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-in-out;
      background: #004a99; }
    .accordion__panel-status-icon::before {
      width: 4px;
      height: 16px; }
    .accordion__panel-status-icon::after {
      width: 16px;
      height: 4px; }
    .accordion__panel-status-icon--active {
      transform: rotate(90deg);
      background: #e0eff9; }
      .accordion__panel-status-icon--active::before {
        height: 8px; }
      .accordion__panel-status-icon--active::after {
        opacity: 0;
        transition: all 0.3s ease-in-out; }

.diamond-background {
  position: relative; }
  .diamond-background__shape {
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    transform: translate(-50%, 150px); }
    .diamond-background__shape svg {
      float: left;
      height: 1440px;
      width: auto; }
    .diamond-background__shape svg path {
      fill: #e0eff9; }

.pagination__list {
  display: inline-flex;
  height: 100%; }

.pagination__list-item {
  border: 1px solid #d8d8d8;
  display: none;
  height: 100%;
  margin-right: -1px; }
  @media (min-width: 992px) {
    .pagination__list-item {
      display: inline-block; } }
  .pagination__list-item--control {
    display: inline-block; }
  .pagination__list-item--mobile {
    border: none;
    font-size: 0.875rem;
    line-height: 48px;
    margin: 0 10px; }
    @media (min-width: 992px) {
      .pagination__list-item--mobile {
        display: none; } }

.pagination__list-link, .pagination__list-icon, .pagination__control-link {
  display: block;
  color: #000000;
  line-height: 1em;
  padding: calc(((50px - 2px) - 1em) / 2);
  font-size: 0.875rem; }
  .pagination__list-link--active, .pagination__list-icon--active, .pagination__control-link--active {
    background-color: #e0eff9;
    color: #004a99;
    font-weight: 700; }

.pagination__control-link {
  color: #000000;
  display: block; }

.news-overview {
  margin: 4rem 0 1.75rem; }
  .news-overview__col {
    margin-bottom: 20px; }
  .news-overview__item {
    display: flex;
    flex-direction: column;
    height: 100%; }
    .news-overview__item:hover {
      text-decoration: none; }
  .news-overview__info-wrapper {
    border: 1px solid #d8d8d8;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
  .news-overview__image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 225px; }
    @media (min-width: 576px) {
      .news-overview__image {
        height: 250px; } }
    @media (min-width: 768px) {
      .news-overview__image {
        height: 300px; } }
    @media (min-width: 1200px) {
      .news-overview__image {
        height: 380px; } }
  .news-overview__title {
    margin: 0 0 1.5rem;
    border-bottom: 0;
    font-size: 1.5rem;
    line-height: 1;
    flex-grow: 1; }
  .news-overview__read-more {
    justify-content: center; }

.popup {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4); }
  .popup--active {
    display: flex;
    justify-content: flex-end; }
  .popup__title {
    color: #ffffff; }
  .popup__content {
    background-color: #fefefe;
    margin: 2rem auto;
    padding: 1.25rem;
    width: 96%;
    /*stylelint-disable*/
    /*stylelint-enable*/ }
    @media (max-width: 767.98px) {
      .popup__content {
        overflow-y: scroll; } }
    @media (min-width: 768px) {
      .popup__content {
        padding: 1.5rem;
        overflow-y: scroll;
        width: 70%; } }
    @media (min-width: 992px) {
      .popup__content {
        padding: 2rem;
        overflow: auto;
        width: 60%; } }
    .popup__content h2, .popup__content h3, .popup__content a {
      color: #004A99; }
    .popup__content h2 {
      font-size: 24px; }
    .popup__content h3 {
      font-size: 14px; }
    .popup__content p {
      color: #000000;
      font-size: 14px; }
    .popup__content .green {
      color: #6ab023; }
    .popup__content .buttons {
      position: relative;
      text-align: center;
      width: 100%; }
      .popup__content .buttons .button {
        color: #ffffff;
        margin: 0 auto; }
  .popup__close {
    background-color: #e0eff9;
    border-radius: 50%;
    color: #ffffff;
    float: right;
    font-size: 31px;
    font-weight: 800;
    height: 45px;
    padding-top: 3px;
    text-align: center;
    width: 45px;
    /*stylelint-disable*/
    /*stylelint-enable*/ }
    .popup__close:hover, .popup__close:focus {
      color: grey;
      text-decoration: none;
      cursor: pointer; }
    .popup__close span {
      color: #004A99; }

@media (min-width: 768px) {
  .zigzag-content__row:not(.zigzag-content__row:nth-last-child(1)) {
    margin-bottom: 4rem; } }

.zigzag-content__col--text {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.zigzag-content__image-wrapper {
  min-height: 500px; }
  @media (min-width: 992px) {
    .zigzag-content__image-wrapper {
      min-height: 600px; } }

.zigzag-content__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  min-height: inherit; }

.zigzag-content__text {
  /* stylelint-disable */
  /* stylelint-enable */ }
  .zigzag-content__text h2 {
    margin-top: 0; }

.interest-block {
  background-color: #004a99;
  margin-bottom: 2rem;
  padding: 30px;
  position: relative; }
  @media (min-width: 992px) {
    .interest-block {
      margin-bottom: 4rem;
      padding: 40px; } }
  .interest-block__title {
    color: #ffffff;
    margin: 0 0 1.5rem; }
    @media (min-width: 992px) {
      .interest-block__title {
        font-size: 2rem; } }
  .interest-block__line {
    color: #ffffff; }
    .interest-block__line svg {
      fill: #6ab023;
      margin-right: .8rem; }
  .interest-block__button {
    margin: 1rem 0 0;
    justify-content: center;
    width: 100%; }
    @media (min-width: 992px) {
      .interest-block__button {
        margin: 1.5rem 1rem 0 0;
        justify-content: normal;
        width: auto; } }
  .interest-block__icon {
    height: 2.5rem;
    width: 2.5rem;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
    display: none; }
    @media (min-width: 1200px) {
      .interest-block__icon {
        display: block; } }
    .interest-block__icon svg {
      fill: #6ab023; }

/* -- Shop components -- */
.price__slider {
  margin-bottom: 20px;
  padding-left: 20px; }

.price__inputs {
  display: flex; }

.price__input-wrapper {
  display: inline-block;
  flex: 1 1 auto; }

.price__separator {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  align-self: center; }

.price__input {
  padding-left: 5px;
  padding-right: 5px;
  text-align: center; }
  @media (min-width: 1200px) {
    .price__input--min {
      text-align: left; }
    .price__input--max {
      text-align: right; } }

.selectr-selected {
  border-radius: 0;
  border-color: #000000;
  padding: 9px 28px 8px 14px; }
  .selectr-selected::before {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Pro";
    height: auto;
    transform: translateY(-50%);
    width: auto; }

.selectr-label {
  font-family: Lemance;
  font-size: 14px;
  line-height: 23px;
  padding-right: 10px; }

.selectr-container.native-open .selectr-selected::before,
.selectr-container.open .selectr-selected::before {
  border: none;
  content: "\f106";
  height: auto;
  transform: translateY(-50%);
  width: auto; }

.sort__form {
  padding-bottom: 20px;
  padding-top: 20px;
  display: flex;
  height: 100%;
  /* stylelint-disable */
  /* stylelint-enable */ }
  @media (min-width: 768px) {
    .sort__form {
      display: inline-block;
      margin-bottom: 0;
      margin-top: 0; } }
  .sort__form .form__group {
    display: inline-flex;
    flex-grow: 2;
    align-items: center;
    margin-bottom: 0; }
    @media (min-width: 768px) {
      .sort__form .form__group {
        flex-grow: 0;
        margin-bottom: 20px; } }
  .sort__form .form__label {
    display: inline-block;
    margin-right: 10px;
    font-weight: 800; }
  .sort__form .form__select-wrapper {
    display: inline-block;
    flex-grow: 2; }
    @media (min-width: 768px) {
      .sort__form .form__select-wrapper {
        flex-grow: 0;
        width: 175px; } }
    @media (min-width: 992px) {
      .sort__form .form__select-wrapper {
        width: 210px; } }

.shop-filters {
  margin-bottom: 60px;
  padding: 25px;
  width: 100%;
  /*stylelint-disable*/
  /*stylelint-enable*/ }
  @media (min-width: 768px) {
    .shop-filters {
      display: inline-block;
      margin-bottom: 25px;
      padding: 0; } }
  .shop-filters__list {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #f4f4f4; }
  .shop-filters__title {
    font-size: 0.875rem;
    margin-bottom: 1.5em;
    display: block; }
  .shop-filters__list--active .shop-filters__sub-list-item:nth-child(n+6) {
    display: block; }
  .shop-filters__sub-list-item:nth-child(n+6) {
    display: none; }
  .shop-filters--prices {
    border-bottom: 0;
    padding-bottom: 0; }
  .shop-filters__toggle {
    align-items: center;
    background: #6ab023;
    bottom: 10px;
    color: #ffffff;
    display: inline-flex;
    justify-content: center;
    position: fixed;
    right: 10px;
    z-index: 95;
    font-size: 14px;
    padding: 15px 10px; }
  .shop-filters__toggle-icon svg {
    width: 1em;
    height: 1em;
    margin-right: .5em; }
  .shop-filters__toggle-icon svg path {
    fill: #ffffff; }
  .shop-filters__filter-toggle--more, .shop-filters__filter-toggle--less {
    cursor: pointer;
    font-size: 14px;
    font-weight: 900; }
    .shop-filters__filter-toggle--more::after, .shop-filters__filter-toggle--less::after {
      content: "\f107";
      font-family: "Font Awesome 5 Pro"; }
  .shop-filters__filter-toggle--less {
    display: none; }
    .shop-filters__filter-toggle--less::after {
      content: "\f106"; }
  .shop-filters__list--active .shop-filters__filter-toggle--more {
    display: none; }
  .shop-filters__list--active .shop-filters__filter-toggle--less {
    display: block; }
  .shop-filters__reset {
    margin-top: 30px;
    display: block; }
  .shop-filters-fixed {
    background-color: #ebebeb;
    border: 1px solid #f4f4f4;
    bottom: 0px;
    height: 70px;
    left: 0px;
    padding: 10px;
    position: fixed;
    right: 0;
    z-index: 93;
    transition: all 0.3s ease-in-out;
    transform: translateX(-100%); }
    .shop-filters-fixed--expanded {
      transform: translateX(0); }

.shop-aside {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90;
  background: #ffffff;
  padding-top: 30px;
  overflow: scroll;
  transition: all 0.3s ease-in-out;
  transform: translateX(-100%);
  /*stylelint-disable*/
  /*stylelint-enable*/ }
  .shop-aside-expanded {
    overflow: hidden; }
  .shop-aside--expanded {
    transform: translateX(0);
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.5); }
  @media (min-width: 768px) {
    .shop-aside {
      right: auto;
      width: 360px;
      padding: 10px;
      padding-top: 80px; } }
  @media (min-width: 992px) {
    .shop-aside {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      width: auto;
      padding: 0;
      overflow: auto;
      transform: none;
      box-shadow: none; } }

.product-overview {
  margin-bottom: 1.75rem; }

.product-detail-info {
  margin-top: 2rem; }

.product-detail__row {
  gap: 1.5rem 0; }

.product-detail__title {
  margin: 0; }

.product-detail__category {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  margin-top: 0; }

.product-detail__info-wrapper {
  padding: 2rem 0 3rem; }

.product-detail__usps {
  margin: 1.5rem 0; }

.product-detail__usp-title {
  margin-top: 0; }

.product-detail__quotation-wrapper {
  background-color: #004a99;
  padding: 3rem 0;
  /* stylelint-disable */
  /* stylelint-enable */ }
  @media (min-width: 992px) {
    .product-detail__quotation-wrapper {
      padding: 6rem 0; } }
  .product-detail__quotation-wrapper form label,
  .product-detail__quotation-wrapper form a {
    color: #ffffff; }

.product-detail__quotation-info {
  /* stylelint-disable */
  /* stylelint-enable */ }
  .product-detail__quotation-info ol {
    counter-reset: my-counter;
    list-style: none;
    padding-left: 72px;
    margin: 4.75rem 0 0;
    font-size: 1.5rem;
    font-weight: 900;
    color: #ffffff; }
    .product-detail__quotation-info ol li {
      margin: 0 0 3rem;
      counter-increment: my-counter;
      position: relative; }
      .product-detail__quotation-info ol li::before {
        content: "";
        position: absolute;
        left: calc(-56px - 17px);
        line-height: 56px;
        width: 56px;
        height: 56px;
        top: calc(-56px / 4);
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA1NiA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNy41ODk3IDBDMjEuNTk3NSAxOC41NzI2IDAgMjguMzA5OCAwIDI4LjMwOThDMCAyOC4zMDk4IDguNTE1MjQgMzAuOTc5NyAxNS4xOTM1IDM3Ljc2ODlDMjEuODY0NSA0NC41NTIgMjcuMTE0NiA1NiAyNy4xMTQ2IDU2QzI3LjExNDYgNTYgMzEuNjc2NSA0Ni41NDI5IDM4Ljc2NDYgMzkuMzQyM0M0NS44NDY1IDMyLjE0NTkgNTYgMjguMzc4NSA1NiAyOC4zNzg1QzU2IDI4LjM3ODUgNDMuMDU1MyAyMS44NjUyIDM3LjA1NyAxNS42OTc2QzMxLjA2MzggOS41Mjg5MiAyNy41ODk3IDAgMjcuNTg5NyAwWiIgZmlsbD0iIzZBQjAyMyIvPgo8L3N2Zz4K"); }
      .product-detail__quotation-info ol li::after {
        content: counter(my-counter);
        color: #ffffff;
        font-size: 1.25rem;
        font-weight: 700;
        position: absolute;
        left: calc(-32px - 17px);
        line-height: 32px;
        width: 32px;
        height: 32px;
        top: 0; }

.product-detail__form-title {
  margin-top: 0;
  color: #ffffff;
  text-align: center; }
  @media (min-width: 768px) {
    .product-detail__form-title {
      text-align: left; } }

.product-detail__accessoires-wrapper {
  padding: 3rem 0 0; }
  @media (min-width: 992px) {
    .product-detail__accessoires-wrapper {
      padding: 4rem 0 0; } }

.product-detail__accessoires-title {
  margin: 0 0 1.5em;
  text-align: center; }

.product-detail__actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem; }
  @media (min-width: 576px) {
    .product-detail__actions {
      flex-flow: row wrap; } }
  @media (min-width: 992px) {
    .product-detail__actions {
      margin-bottom: 3.125rem; } }

.product-detail__action {
  flex: 0 0 auto;
  justify-content: center;
  /* stylelint-disable */
  /* stylelint-enable */ }
  @media (min-width: 768px) {
    .product-detail__action {
      flex: 1 0 auto; } }
  @media (min-width: 992px) {
    .product-detail__action {
      flex: 1 0 calc(50% - .5rem); } }
  .product-detail__action--primary {
    background-color: #da5b00; }
    .product-detail__action--primary:hover, .product-detail__action--primary:focus-visible {
      background: #b84d00; }
  .product-detail__action .button {
    width: 100%;
    justify-content: center; }

.product-detail__action-info {
  flex: 0 0 100%;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .product-detail__action-info p {
    margin-bottom: 0; }

.product-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1em;
  padding: 2.5rem 2rem;
  border: 1px solid #d8d8d8;
  box-shadow: inset 0 0 0 2px transparent;
  transition: all 0.3s ease-in-out; }
  @media (min-width: 576px) {
    .product-card {
      height: 100%; } }
  @media (min-width: 576px) {
    .product-card__wrapper {
      padding-bottom: 20px;
      height: 100%; } }
  .product-card:hover {
    border: 1px solid #004a99;
    box-shadow: inset 0 0 0 2px #004a99; }
  .product-card__block {
    margin-bottom: 60px; }
  .product-card__link {
    display: flex;
    flex-direction: column;
    height: 100%; }
    .product-card__link:hover {
      text-decoration: none; }
  .product-card__image-wrapper {
    text-align: center; }
  .product-card__content-wrapper {
    flex-grow: 1; }
  .product-card__image {
    display: inline-block;
    min-width: 1px;
    min-height: 1px;
    height: 200px;
    max-width: 100%; }
  .product-card__diamond svg {
    height: 200px; }
  .product-card__diamond svg path {
    fill: #e0eff9; }
  .product-card__title {
    margin: 1rem 0 .5rem; }
  .product-card__category {
    margin: 0 0 1rem;
    font-size: 1rem;
    font-weight: 400;
    color: #000000; }
  .product-card__button {
    width: 100%;
    justify-content: center; }

.product-slider {
  position: relative;
  /* stylelint-disable */
  /* stylelint-enable */
  /* stylelint-disable */
  /* stylelint-enable */ }
  @media (min-width: 768px) {
    .product-slider {
      position: sticky;
      top: 6.5rem; } }
  @media (min-width: 992px) {
    .product-slider {
      top: 6.75rem; } }
  @media (min-width: 1200px) {
    .product-slider {
      top: 6.75rem; } }
  .product-slider__main {
    border: 1px solid #d8d8d8;
    height: 250px;
    padding: 2rem;
    /* stylelint-disable */
    /* stylelint-enable */ }
    @media (min-width: 768px) {
      .product-slider__main {
        height: 200px; } }
    @media (min-width: 992px) {
      .product-slider__main {
        height: 350px; } }
    @media (min-width: 992px) {
      .product-slider__main {
        padding: 3.375rem 4.75rem; } }
    .product-slider__main .flickity-button.previous, .product-slider__main .flickity-button.next {
      display: none; }
    .product-slider__main.is-fullscreen {
      border-color: transparent; }
      .product-slider__main.is-fullscreen .flickity-button.previous, .product-slider__main.is-fullscreen .flickity-button.next {
        display: block; }
  .product-slider__label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 30px;
    border-top-left-radius: 0;
    z-index: 10; }
  .product-slider__slide {
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    display: flex;
    height: 100%;
    width: 100%; }
  .product-slider__nav {
    margin-left: -5px;
    margin-right: -5px;
    margin-top: 10px; }
  .product-slider__slide-nav {
    display: inline-block;
    height: 100px;
    padding: 0 5px;
    width: 33.333%; }
    @media (min-width: 768px) {
      .product-slider__slide-nav {
        height: 115px; } }
    .product-slider__slide-nav.is-selected .product-slider__slide-nav-image-wrapper {
      border-color: #6ab023; }
  .product-slider__slide-nav-image-wrapper {
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    border: 1px solid #d8d8d8;
    cursor: pointer;
    display: inline-flex;
    padding: 10px;
    height: 100%;
    width: 100%; }
  .product-slider__slide-nav-image {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: contain; }

.flickity-slider {
  cursor: pointer; }

/* stylelint-disable */
#imgClose {
  position: fixed;
  right: 10%;
  top: 20px;
  color: #ffffff;
  font-size: 40px;
  background: none;
  z-index: 9999;
  border: none;
  outline: none;
  padding: 0 0 0 0; }
  @media only screen and (max-width: 768px) {
    #imgClose {
      top: 5%;
      right: 25px;
      padding: 0 0 0 0; } }

#imgLeft {
  position: fixed;
  left: 10%;
  top: 40%;
  color: #ffffff;
  height: 30px;
  width: 30px;
  font-size: 40px;
  z-index: 9999;
  border: none;
  background: url("../images/left.svg") no-repeat;
  outline: none;
  padding: 0 0 0 0; }
  @media only screen and (max-width: 768px) {
    #imgLeft {
      top: 45%;
      left: 25px;
      padding: 0 0 0 0; } }

#imgRight {
  position: fixed;
  right: 10%;
  top: 40%;
  color: #ffffff;
  height: 30px;
  width: 30px;
  font-size: 40px;
  z-index: 9999;
  border: none;
  background: url("../images/right.svg") no-repeat;
  outline: none;
  padding: 0 0 0 0; }
  @media only screen and (max-width: 768px) {
    #imgRight {
      top: 45%;
      right: 25px;
      padding: 0 0 0 0; } }

/* stylelint-enable */
.product-advice__top {
  background: #e0eff9;
  padding: 1rem 1.25rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between; }
  @media (min-width: 992px) {
    .product-advice__top {
      padding: 1rem 1.25rem; } }

.product-advice__content {
  padding: 1rem 0; }
  @media (min-width: 576px) {
    .product-advice__content {
      padding: 1rem 0 1rem .75rem; } }

.product-advice__title {
  margin-bottom: 1rem;
  margin-top: 0;
  line-height: .875rem; }

.product-advice__link {
  display: flex;
  align-items: center;
  color: #000000;
  line-height: 1.625; }
  .product-advice__link svg {
    margin-right: .5em;
    max-width: 14px; }
  .product-advice__link svg path {
    fill: #004a99; }

.product-advice__image {
  min-width: 1px;
  min-height: 1px;
  max-width: 130px;
  margin-top: 1em; }
  @media (min-width: 768px) {
    .product-advice__image {
      margin-top: 0; } }

.product-advice__bottom {
  padding: 10px 20px 20px 30px;
  background: #004a99; }
  @media (min-width: 992px) {
    .product-advice__bottom {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px 10px 30px; } }

.product-advice__cta-title {
  margin-top: .3em;
  margin-bottom: .3em;
  color: #ffffff; }
