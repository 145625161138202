.title-dotted {
    &__icon {
        vertical-align: text-top;

        svg {
            height: 0.35em;
            width: 0.35em;
        }

        svg path {
            fill: map-deep-get($colors, "brand", "secondary");
        }
    }
}