/*stylelint-disable*/
@font-face {
    font-family: 'Calima';
    src: local('CalimaRegular'),
    url('../fonts/CalimaRegular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calima';
    src: local('CalimaSemibold'),
    url('../fonts/CalimaSemibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calima';
    src: local('CalimaHeavy'),
    url('../fonts/CalimaHeavy.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
/*stylelint-enable*/
