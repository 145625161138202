.shop-aside {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 90;
    background: #ffffff;
    padding-top: 30px;
    overflow: scroll;
    transition: $transition-default;
    transform: translateX(-100%);

    /*stylelint-disable*/
    &-expanded {
        overflow: hidden;
    }
    /*stylelint-enable*/

    &--expanded {
        transform: translateX(0);
        box-shadow: 0 5px 30px rgba(map-deep-get($colors, "black", "default"), .5);
    }

    @include media-breakpoint-up(md) {
        right: auto;
        width: 360px;
        padding: 10px;
        padding-top: 80px;
    }

    @include media-breakpoint-up(lg) {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        width: auto;
        padding: 0;
        overflow: auto;
        transform: none;
        box-shadow: none;
    }
}
