.footer {
    background-color: map-deep-get($colors, "white", "default");
    color: map-deep-get($colors, "black", "default");
    position: relative;
    z-index: 15;
    flex: 1 1 100%;

    @include media-breakpoint-up(lg) {
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            background-color: map-deep-get($colors, "brand", "primary-light");
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 116px;
            right: 0;
            bottom: 0;
            width: 50%;
            background-color: map-deep-get($colors, "brand", "tertiary");
        }
    }

    &__left {
        position: relative;
        z-index: 1;
        padding: 45px 10px;
        margin: 0 -10px;
        height: 100%;
        border-top-right-radius: 100px;
        background: map-deep-get($colors, "brand", "primary-light");

        @include media-breakpoint-up(md) {
            padding: 90px 90px 20px 0;
            margin-right: -10px;
        }
    }

    &__right {
        position: relative;
        z-index: 1;
        margin: 0 -10px;
        display: flex;
        flex-direction: column;
        height: 100%;
        text-align: center;

        @include media-breakpoint-up(md) {
            margin-left: -10px;
        }
    }

    &__contact-option {
        display: flex;
        align-items: center;
        font-weight: 800;
        margin-bottom: 70px;

        svg {
            margin-right: 24px;
        }
    }

    &__contact-option-label {
        display: inline-block;
    }

    &__pay-off {
        padding: 30px 10px 20px;

        svg {
            max-width: 100%;
        }
    }

    &__partner {
        padding: 60px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__partner-label {
        display: inline-block;
        padding-top: 26px;
        font-weight: 900;
    }

    &__credits {
        flex: auto;
        padding-bottom: 45px;
        color: map-deep-get($colors, "white", "default");
        background: map-deep-get($colors, "brand", "tertiary");
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__list-item {
        width: 100%;
        flex: 0 0 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 100;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        margin-bottom: 0.5em;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            width: auto;
            flex: 0 0 auto;
            text-align: left;
            padding-right: 10px;

            &::after {
                content: "|";
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
            }

            &:last-child::after {
                content: none;
            }
        }
    }

    &__link {
        color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }
}
