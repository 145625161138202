.breadcrumb {
    margin: 20px 0 10px;
    font-size: 14px;
    font-weight: 900;
    color: map-deep-get($colors, "brand", "primary");

    @include media-breakpoint-up(md) {
        display: flex;
    }

    svg {
        height: 90%;
        width: 4px;
        margin: 0 .25em;
    }

    svg path {
        fill: map-deep-get($colors, "gray", "darker");
    }

    &__list-item {
        display: inline-block;

        @include media-breakpoint-up(md) {
            display: unset;
        }
    }

    &__link {
        font-weight: 400;
        color: map-deep-get($colors, "gray", "darker");
    }
}