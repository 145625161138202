.price {
    &__slider {
        margin-bottom: 20px;
        padding-left: 20px;
    }

    &__inputs {
        display: flex;
    }

    &__input-wrapper {
        display: inline-block;
        flex: 1 1 auto;
    }

    &__separator {
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
        align-self: center;
    }

    &__input{
        padding-left: 5px;
        padding-right: 5px;
        text-align: center;

        @include media-breakpoint-up(xl) {
            &--min {
                text-align: left;
            }

            &--max {
                text-align: right;
            }
        }
    }
}