.product-navigation {
    $elm: &;

    &__list {
        display: block;
        flex-direction: row;
        flex: auto;
        vertical-align: center;

        @include media-breakpoint-up(lg) {
            justify-content: flex-end;
            list-style: none;
            display: flex;
            padding: 0;
            width: auto;
            height: 60%;
            min-height: auto;
        }

        &--sub {
            display: none;
            padding: 8px;

            @include media-breakpoint-up(lg) {
                position: absolute;
                top: 100%;
                left: -8px;
                height: auto;
                border: 1px solid map-deep-get($colors, "gray", "dark");
                border-top: none;
                background: map-deep-get($colors, "white", "default");
                min-width: 220px;
            }

            #{$elm}__list-item {
                display: block;
                width: 100%;
                margin: 0;
                padding: 5px 0;
            }

            #{$elm}__item {
                font-size: rem-calc(14);

                @include media-breakpoint-up(lg) {
                    color: map-deep-get($colors, "brand", "primary");
                }
            }
        }

        @at-root #{$elm}__last-item & {
            @include media-breakpoint-up(lg) {
                right: -8px;
                left: auto;
            }
        }
    }

    &__list-item {
        transition: all $transition-default-time #{$transition-default-time / 2} $transition-default-smoothing;
        opacity: 0;
        flex-wrap: wrap;
        flex: 1;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 25px 0 10px;
        width: auto;
        text-align: center;
        display: block;

        @include media-breakpoint-up(lg) {
            flex: unset;
            align-items: center;
            padding: 20px 0;
            margin: 0;
            opacity: 1;
            width: auto;
            float: left;
            height: 100%;
        }

        @include media-breakpoint-up(xl) {
            margin: 0 8px;
        }

        svg {
            margin-left: 1px;
        }

        svg path {
            fill: map-deep-get($colors, "brand", "primary");
            transition: $transition-default;
        }

        &:hover {
            svg path {
                fill: map-deep-get($colors, "black", "default");
            }

            > #{$elm}__item {
                text-decoration: underline;
                color: map-deep-get($colors, "black", "default");
            }
        }

        &--has-sub {
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: auto;
            }

            /*stylelint-disable*/
            &:hover {
                #{$elm}__list--sub {
                    @include media-breakpoint-up(lg) {
                        display: block;
                    }
                }
            }

            //&#{$elm}--opened { //product-navigation--opened
            //    #{$elm}__list--sub {
            //        display: block;
            //    }
            //}

            /*stylelint-enable*/
        }
    }

    &__list-item-level-2 {
        display: flex;

        svg {
            transform: rotate(270deg);
        }
    }

    &__item {
        display: inline-block;
        font-size: rem-calc(15);
        font-weight: 900;
        line-height: 1.25em;
        color: map-deep-get($colors, "brand", "primary");
        padding: 5px 0 5px 5px;
        white-space: nowrap;
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(13);
        }

        @include media-breakpoint-up(lg) {
            text-align: left;
        }

        @include media-breakpoint-up(xl) {
            font-size: rem-calc(15);
        }

        &--active {
            text-decoration: underline;
        }
    }

    &--active {
        #{$elm}__list-item {
            opacity: 1;
            padding: 5px 0;
        }
    }

    /* stylelint-disable */
    &__list-item--has-sub {
        & #{$elm}__list--sub { //lvl1
            #{$elm}__list-item--has-sub {
                &:hover {
                    & > #{$elm}__list--sub {
                        display: block;
                    }
                }
            }

            & #{$elm}__list--sub { //lvl2
                display: none;

                @include media-breakpoint-up(lg) {
                    display: block;
                    border: 1px solid map-deep-get($colors, "gray", "dark");
                }

                #{$elm}__item {
                    font-size: rem-calc(12);
                    line-height: 1.1em;
                    color: map-deep-get($colors, "black", "default");
                }

                @include media-breakpoint-up(lg) {
                    display: none;
                    position: absolute;
                    top: -8px;
                    left: 100%;
                    width: 100%;
                    background-color: map-deep-get($colors, "white", "default");

                    &#{$elm}--left { // .product-navigation--left
                        left: calc(-100% + -15px);
                    }
                }
            }
        }
    }
    /* stylelint-enable */

    #{$elm}--opened { //product-navigation--opened
        > #{$elm}__list--sub {
            display: block;
        }
    }
}
