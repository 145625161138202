.header {
    $block: &;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99; // z-index is om te zorgen dat deze boven de barba laag zit. Hierdoor wordt voor het oog alleen de content vervangen
    transition: $transition-default;

    &--inverted {
        background: transparent;

        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 120%;
            opacity: .3;
            background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        }

        #{$block}__main {
            background: transparent;
        }

        #{$block}__logo {
            svg path {
                fill: map-deep-get($colors, "white", "default");
            }
        }

        #{$block}__logo-image {
            display: none;

            &--inverted {
                display: block;
            }
        }
    }

    &--scrolled {
        height: #{map-deep-get($header-height, "main-scrolled", "xs") + map-deep-get($header-height, "top", "xs")};
        margin-top: 0;
        top: -#{map-deep-get($header-height, "top", "xs")};
        background: map-deep-get($colors, "white", "default");

        @include media-breakpoint-up(md) {
            height: #{map-deep-get($header-height, "main-scrolled", "md") + map-deep-get($header-height, "top", "md")};
            top: -#{map-deep-get($header-height, "top", "md")};
        }

        @include media-breakpoint-up(lg) {
            height: #{map-deep-get($header-height, "main-scrolled", "lg") + map-deep-get($header-height, "top", "lg")};
            top: -#{map-deep-get($header-height, "top", "lg")};
        }

        @include media-breakpoint-up(xl) {
            height: #{map-deep-get($header-height, "main-scrolled", "xl") + map-deep-get($header-height, "top", "xl")};
            top: -#{map-deep-get($header-height, "top", "xl")};
        }

        #{$block}__logo {
            padding: 1rem 0;

            @include media-breakpoint-up(lg) {
                padding: .5rem 0;
            }
        }
    }

    &--show-topbar {
        top: 0;

        #{$block}__logo {
            padding: 1rem 0;

            @include media-breakpoint-up(lg) {
                padding: .1rem 0;
            }
        }
    }

    &__container {
        @extend .container; // Switch to .container or .container-fluid
        display: flex;
        flex-flow: row wrap;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    &__top {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        height: map-deep-get($header-height, "top", "xs");
        color: map-deep-get($colors, "white", "default");
        background-color: map-deep-get($colors, "brand", "secondary");
        transition: $transition-default, background .1s;

        @include media-breakpoint-up(md) {
            height: map-deep-get($header-height, "top", "md");
        }

        @include media-breakpoint-up(lg) {
            height: map-deep-get($header-height, "top", "lg");
        }

        @include media-breakpoint-up(xl) {
            height: map-deep-get($header-height, "top", "xl");
        }

        &--no-bg {
            @at-root #{$block}:not(#{$block}--scrolled) & {
                @include media-breakpoint-up($navigation-media-breakpoint-up) {
                    background-color: transparent;
                }
            }
        }
    }

    &__language-switch {
        flex: 0 0 auto;
        max-width: 100%;
    }

    &__priority-navigation {
        flex: 1 1 auto;
        max-width: 100%;
    }

    &__main {
        display: flex;
        align-items: center;
        height: map-deep-get($header-height, "main", "xs");
        background: map-deep-get($colors, "white", "default");
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            height: map-deep-get($header-height, "main", "md");
        }

        @include media-breakpoint-up(lg) {
            height: map-deep-get($header-height, "main", "lg");
        }

        @include media-breakpoint-up(xl) {
            height: map-deep-get($header-height, "main", "xl");
        }

        &--no-bg {
            @include media-breakpoint-up(md) {
                background-color: transparent;
            }
        }
    }

    &__main-container {
        flex-flow: row;
    }

    &__brand {
        transform: translateY(0);
        transition: $transition-default;
        align-self: center;

        @include media-breakpoint-up($navigation-media-breakpoint-up) {
            flex: 1 1 auto;
        }
    }

    &__logo {
        display: block;
        flex: 0 0 auto;
        padding: 1rem 0;
        height: map-deep-get($header-height, "main", "xs");
        transition: $transition-default;

        @include media-breakpoint-up(lg) {
            padding: .1rem 0;
        }

        &--tennant {
            height: 3.5rem;

            @include media-breakpoint-up(lg) {
                height: 2.5rem;
            }
        }

        svg {
            height: 100%;
            transform: scale(1);
            transition: $transition-default;
            width: auto;

            @include media-breakpoint-up(lg) {
                @at-root #{$block}--scrolled:not(.header--show-topbar) & {
                    transform: scale(.9);
                }
            }
        }
    }

    &__buttons {
        order: 2;
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @include media-breakpoint-up($navigation-media-breakpoint-up) {
            order: 3;
            flex: 0 0 auto;
        }
    }

    &__navigation {
        order: 3;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @include media-breakpoint-up($navigation-media-breakpoint-up) {
            order: 2;
            flex: 0 0 auto;
        }
    }

    &__logo-image {
        height: 100%;

        &--inverted {
            display: none;
        }
    }

    &__menu-toggle {
        display: flex;
        align-items: center;
        float: right;
        position: relative;

        @include media-breakpoint-up($navigation-media-breakpoint-up) {
            display: none;
        }
    }
}
