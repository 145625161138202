.accordion {
    font-family: sans-serif;
    padding-bottom: 20px;
    $elm: &;

    &__panel {
        background: map-deep-get($colors, "white", "default");
    }

    &__panel-title {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        padding: 10px 20px;
        margin: 0;
        line-height: rem-calc(40);
        color: map-deep-get($colors, "brand", "primary");
        border-radius: 3px;
        background: map-deep-get($colors, "brand", "primary-light");

        &:hover {
            text-decoration: underline;
        }

        &--active {
            background: map-deep-get($colors, "white", "default");
        }
    }

    &__panel-content {
        overflow: hidden;
        transition: $transition-default;
    }

    &__panel-content-inner {
        padding: 10px 60px 10px 20px;

        /* stylelint-disable */
        .rich-text-area {
            ul {
                margin: 0 0 1rem;
            }
        }
        /* stylelint-enable */
    }

    &__panel-status-icon {
        position: relative;
        display: inline-block;
        width: 40px;
        min-width: 40px;
        height: 40px;
        font-size: 0;
        color: transparent;
        border-radius: 50%;
        background: map-deep-get($colors, "white", "default");
        transition: $transition-default;

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform-origin: 0 0;
            transform: translate(-50%, -50%);
            transition: $transition-default;
            background: map-deep-get($colors, "brand", "primary");
        }

        &::before {
            width: 4px;
            height: 16px;
        }

        &::after {
            width: 16px;
            height: 4px;
        }

        &--active {
            transform: rotate(90deg);
            background: map-deep-get($colors, "brand", "primary-light");

            &::before {
                height: 8px;
            }

            &::after {
                opacity: 0;
                transition: $transition-default;
            }
        }
    }
}
