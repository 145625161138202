﻿h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: $font-family-base;
    font-weight: 900;
    color: map-deep-get($colors, "brand", "primary");
}

p {
    margin-top: 0;
}

h1, .h1 {
    font-size: rem-calc(30);
    line-height: 1.2em;
    margin-bottom: 0.5em;
    margin-top: .75em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(42);
    }

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(50);
    }
}

h2, .h2 {
    font-size: rem-calc(28);
    line-height: 1.25em;
    margin-bottom: 0.5em;
    margin-top: 1em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(36);
    }

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(40);
    }
}

h3, .h3 {
    font-size: rem-calc(26);
    line-height: 1.33em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(30);
    }
}

h4, .h4 {
    font-size: rem-calc(24);
    line-height: 1.36em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(28);
    }
}

h5, .h5 {
    font-size: rem-calc(21);
    line-height: 1.42em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(24);
    }
}

h6, .h6 {
    font-size: rem-calc(18);
    line-height: 1.55em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(20);
    }
}

strong {
    font-weight: 900;
}