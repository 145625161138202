.product-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1em;
    padding: 2.5rem 2rem;
    border: 1px solid map-deep-get($colors, "gray", "dark");
    box-shadow: inset 0 0 0 2px transparent;
    transition: $transition-default;

    @include media-breakpoint-up(sm) {
        height: 100%;
    }

    &__wrapper {
        @include media-breakpoint-up(sm) {
            padding-bottom: 20px;
            height: 100%;
        }
    }

    &:hover {
        border: 1px solid map-deep-get($colors, "brand", "primary");
        box-shadow: inset 0 0 0 2px map-deep-get($colors, "brand", "primary");
    }

    &__block {
        margin-bottom: 60px;
    }

    &__link {
        display: flex;
        flex-direction: column;
        height: 100%;

        &:hover {
            text-decoration: none;
        }
    }

    &__image-wrapper {
        text-align: center;
    }

    &__content-wrapper {
        flex-grow: 1;
    }

    &__image {
        display: inline-block;
        min-width: 1px;
        min-height: 1px;
        height: 200px;
        max-width: 100%;
    }

    &__diamond {
        svg {
            height: 200px;
        }

        svg path {
            fill: map-deep-get($colors, "brand", "primary-light");
        }
    }

    &__title {
        margin: 1rem 0 .5rem;
    }

    &__category {
        margin: 0 0 1rem;
        font-size: 1rem;
        font-weight: 400;
        color: map-deep-get($colors, "black", "default");
    }

    &__button {
        width: 100%;
        justify-content: center;
    }
}
