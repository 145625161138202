.navigation-item {
    $block: &;
    display: block;
    padding: 10px 0;
    position: relative;
    opacity: 0;
    transition: $transition-default;
    cursor: pointer;

    @include media-breakpoint-up($navigation-media-breakpoint-up) {
        display: inline-flex;
        padding: 0;
        position: static;
        opacity: 1;
    }

    &--inverted {
        #{$block}__link {
            @include media-breakpoint-up($navigation-media-breakpoint-up) {
                color: map-deep-get($colors, "white", "default");
            }

            &--sub {
                @include media-breakpoint-up($navigation-media-breakpoint-up) {
                    color: map-deep-get($colors, "black", "default");
                }
            }
        }

        #{$block}__sub-icon {
            svg path {
                @include media-breakpoint-up($navigation-media-breakpoint-up) {
                    fill: map-deep-get($colors, "white", "default");
                }
            }
        }
    }

    &--has-sub {
        &:hover,
        &:focus,
        &:focus-within {
            > #{$block}__link {
                color: map-deep-get($colors, "black", "default");

                /* stylelint-disable */
                > #{$block}__sub-icon--mobile svg path {
                    fill: map-deep-get($colors, "black", "default");
                }

                /* stylelint-enable */
            }

            > #{$block}__link--sub {
                @include media-breakpoint-up($navigation-media-breakpoint-up) {
                    color: map-deep-get($colors, "white", "default");
                }
            }

            #{$block}__sub-icon--desktop svg path {
                fill: map-deep-get($colors, "black", "default");
            }

            > #{$block}__sub-list {
                @include media-breakpoint-up($navigation-media-breakpoint-up) {
                    transform: translateY(0);
                    opacity: 1;
                    pointer-events: visible;
                    cursor: default;
                }
            }
        }

        #{$block} {
            &:hover > #{$block}__link,
            &:focus > #{$block}__link,
            &:focus-within > #{$block}__link {
                @include media-breakpoint-up($navigation-media-breakpoint-up) {
                    opacity: 1;
                }
            }

            &--active #{$block}__link {
                opacity: 1;
            }

            #{$block}__link {
                @include media-breakpoint-up($navigation-media-breakpoint-up) {
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 1.625rem;
                    padding: 0;
                }
            }

            #{$block}__link--bold {
                @include media-breakpoint-up($navigation-media-breakpoint-up) {
                    font-weight: 900;
                    font-size: 1.2rem;
                }

                @include media-breakpoint-up(xxl) {
                    font-size: 1.25rem;
                }
            }

            #{$block}__link--parent {
                @include media-breakpoint-up($navigation-media-breakpoint-up) {
                    font-weight: 500;
                    font-size: 2rem;
                    margin-bottom: .25rem;
                    position: relative;
                    width: fit-content;
                }
            }
        }
    }

    &--paginated {
        &#{$block}--active {
            > #{$block}__sub-list {
                @include media-breakpoint-down($navigation-media-breakpoint-down) {
                    left: 0;
                }
            }
        }

        > #{$block}__sub-list {
            @include media-breakpoint-down($navigation-media-breakpoint-down) {
                position: fixed;
                z-index: 1;
                top: 0;
                left: 100%;
                padding: 80px 16px 16px;
                width: 100%;
                height: 100%;
                background-color: map-deep-get($colors, "white", "default");
            }

            &--has-topbar {
                @include media-breakpoint-down($navigation-media-breakpoint-down) {
                    top: map-deep-get($header-height, "top", $navigation-media-breakpoint-down);
                }
            }
        }

        #{$block}__link {
            text-align: left;
            display: flex;
            justify-content: space-between;

            &--parent {
                display: flex;
                justify-content: flex-start;
                padding: .25rem .25rem .75rem;
                font-size: 1.5rem;
                font-weight: 500;

                @include media-breakpoint-up($navigation-media-breakpoint-up) {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    padding: .25rem 1rem 1.25rem;
                    color: map-deep-get($colors, "white", "default");
                }

                // Nesting level
                /* stylelint-disable */
                &:hover {
                    @include media-breakpoint-up($navigation-media-breakpoint-up) {
                        color: map-deep-get($colors, "white", "default");
                    }
                }

                /* stylelint-enable */
            }
        }
    }

    &--parent {
        @include media-breakpoint-up($navigation-media-breakpoint-up) {
            position: absolute;
            top: 0;
            left: 3rem;
            right: 3rem;
            transform: translateY(100%);
            border-bottom: 1px solid transparentize(map-deep-get($colors, "white", "default"), 0.9);
            padding: 0 0 1.25rem;
            width: 100%;
        }

        @include media-breakpoint-up(xxl) {
            left: 6.875rem;
            right: 6.875rem;
        }
    }

    &--first-sub {
        margin-bottom: 1.25rem;
        display: block;
        break-inside: avoid;
    }

    &__link {
        display: inline-block;
        padding: 5px;
        width: 100%;
        line-height: 1.25em;
        font-size: rem-calc(24);
        font-weight: 500;
        text-align: center;
        white-space: nowrap;
        color: map-deep-get($colors, "brand", "primary");
        transition: $transition-default;

        @include media-breakpoint-up($navigation-media-breakpoint-up) {
            font-weight: 900;
            padding: 1.5rem .5rem;
            font-size: rem-calc(18);
            text-align: left;
        }

        @media (min-width: 1400px) {
            padding: 2rem 1rem;
        }

        &:hover {
            text-decoration: none;

            #{$block}__hover-icon {
                opacity: 1;
                transform: translateX(0);
            }
        }

        &--current {
            color: map-deep-get($colors, "black", "default");

            #{$block}__sub-icon--desktop svg path {
                fill: map-deep-get($colors, "brand", "primary");
            }
        }

        &--sub {
            font-size: rem-calc(20);
            letter-spacing: .025em;
            color: map-deep-get($colors, "brand", "primary");

            @include media-breakpoint-up($navigation-media-breakpoint-up) {
                color: map-deep-get($colors, "white", "default");
            }

            @include media-breakpoint-down($navigation-media-breakpoint-down) {
                color: map-deep-get($colors, "brand", "primary");
            }

            @include media-breakpoint-up($navigation-media-breakpoint-up) {
                padding: .25rem 1rem;
            }

            &:hover {
                color: map-deep-get($colors, "black", "default");

                @include media-breakpoint-up($navigation-media-breakpoint-up) {
                    color: map-deep-get($colors, "white", "default");
                    text-decoration: underline;
                }
            }
        }
    }

    &__link-navigation-handler {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @include media-breakpoint-up($navigation-media-breakpoint-up) {
            display: none;
        }
    }

    &__sub-list {
        margin-top: 0;
        padding: .5rem;
        transition: $transition-default;

        @include media-breakpoint-up($navigation-media-breakpoint-up) {
            column-count: 4;
            position: absolute;
            z-index: 9;
            top: 100%;
            left: 0;
            padding: 10rem 3rem 3rem;
            color: map-deep-get($colors, "black", "default");
            background-color: map-deep-get($colors, "brand", "primary");
            box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.2);
            width: 100%;
            transform: translateY(-20px);
            opacity: 0;
            pointer-events: none;
        }

        @include media-breakpoint-up(xxl) {
            padding: 10rem 6.875rem 3rem;
        }

        &--sub {
            left: 100%;
            top: 0;
            position: static;
            padding: 0;
            opacity: 1;
            box-shadow: none;
            transform: none;
            display: flex;
            flex-direction: column;
        }

        #{$block}__list-item {
            display: block;
            width: 100%;
        }

        #{$block}__item {
            font-weight: 500;
            line-height: 1.5em;
            font-size: rem-calc(16);
        }
    }

    &__sub-icon {
        svg {
            margin-left: 8px;
            width: 20px;
            height: 20px;
        }

        svg path {
            fill: map-deep-get($colors, "brand", "primary");
            transition: $transition-default;
        }

        &--mobile {
            @include media-breakpoint-up($navigation-media-breakpoint-up) {
                display: none;
            }
        }

        &--desktop {
            @include media-breakpoint-down($navigation-media-breakpoint-down) {
                display: none;
            }

            svg {
                width: 10px;
                height: 16px;
            }
        }
    }

    &__hover-icon {
        display: none;
        margin-left: .75rem;
        opacity: 0;
        transform: translateX(-1rem);
        transition: $transition-default;

        @include media-breakpoint-up($navigation-media-breakpoint-up) {
            display: block;
        }

        svg path {
            fill: map-deep-get($colors, "brand", "secondary");
        }

        &--parent {
            @include media-breakpoint-up($navigation-media-breakpoint-up) {
                position: absolute;
                top: 0;
                left: 100%;
                transform: translateX(50%);
                opacity: 1;
            }

            svg path {
                fill: map-deep-get($colors, "white", "default");
            }
        }
    }
}
