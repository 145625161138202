.diamond-background {
    position: relative;

    &__shape {
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 0;
        transform: translate(-50%, 150px);

        svg {
            float: left;
            height: 1440px;
            width: auto;
        }

        svg path {
            fill: map-deep-get($colors, "brand", "primary-light");
        }
    }
}