.form {
    overflow: hidden;
    transition: $transition-default;
    $form: &;

    &__group-wrapper {
        &--half {
            #{$form}__group:last-of-type {
                margin-bottom: rem-calc(24);
            }

            @include media-breakpoint-up(md) {
                display: flex;
                margin: 0 rem-calc(-8);

                #{$form}__group {
                    margin: 0 rem-calc(8) rem-calc(24);
                    width: 50%;
                }
            }
        }
    }

    &__group {
        margin-bottom: 1.2em;
    }

    &__invalid-message {
        color: map-deep-get($colors, "validation", "invalid");
        display: none;

        // Eigen v-cloak variant omdat de webkey formulieren niet te bewerken zijn
        /* stylelint-disable */
        &[show] {
            display: block;
        }
        /* stylelint-enable */
    }

    &__label {
        display: block;
        font-weight: 400;
        margin-bottom: .1rem;
    }

    &--loading {
        opacity: .5;
    }

    @include media-breakpoint-down(sm) {
        legend {
            font-size: 1rem;
            margin-bottom: 12px;
        }
    }
}
