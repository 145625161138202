.popup {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, .4);

    &--active {
        display: flex;
        justify-content: flex-end;
    }

    &__title {
        color: #ffffff;
    }

    &__content {
        background-color: #fefefe;
        margin: 2rem auto;
        padding: 1.25rem;
        width: 96%;

        @include media-breakpoint-down(sm) {
            overflow-y: scroll;
        }

        @include media-breakpoint-up(md) {
            padding: 1.5rem;
            overflow-y: scroll;
            width: 70%;
        }

        @include media-breakpoint-up(lg) {
            padding: 2rem;
            overflow: auto;
            width: 60%;
        }

        /*stylelint-disable*/
        h2, h3, a {
            color: #004A99;
        }

        h2 {
            font-size: 24px;
        }

        h3 {
            font-size: 14px;
        }

        p {
            color: #000000;
            font-size: 14px;
        }


        .green {
            color: #6ab023;
        }

        .buttons {
            position: relative;
            text-align: center;
            width: 100%;

            .button {
                color: #ffffff;
                margin: 0 auto;
            }
        }
        /*stylelint-enable*/
    }

    &__close {
        background-color: #e0eff9;
        border-radius: 50%;
        color: #ffffff;
        float: right;
        font-size: 31px;
        font-weight: 800;
        height: 45px;
        padding-top: 3px;
        text-align: center;
        width: 45px;

        /*stylelint-disable*/
        &:hover, &:focus {
            color: grey;
            text-decoration: none;
            cursor: pointer;
        }

        span {
            color: #004A99;
        }
        /*stylelint-enable*/
    }
}
