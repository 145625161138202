.navigation {
    $block: &;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    padding: 80px 16px 20px;
    height: 100%;
    width: 100%;
    transition: $transition-default;
    opacity: 0;
    pointer-events: none;

    @include media-breakpoint-down($navigation-media-breakpoint-down) {
        background: map-deep-get($colors, "white", "default");
    }

    @include media-breakpoint-up($navigation-media-breakpoint-up) {
        display: inline-block;
        position: static;
        overflow: visible;
        margin: 0 0 0 1rem;
        padding: 0;
        width: auto;
        height: auto;
        opacity: 1;
        pointer-events: auto;
    }

    &__back {
        background-color: map-deep-get($colors, "brand", "secondary");
        opacity: 0;
        pointer-events: none;
        transition: $transition-default;
        padding: 0.625rem;
        min-height: auto;
        border-radius: 50%;
        line-height: 1.25rem;
        border-color: transparent;

        &--active {
            opacity: 1;
            pointer-events: auto;
        }

        svg {
            width: 1rem;
            height: 1rem;
            margin: 0;
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &__close {
        margin-right: 1rem;
    }

    &__container {
        @extend .container-fluid; // Switch to .container or .container-fluid
        display: flex;
        flex-flow: row wrap;
        padding: 0 1.5rem;
    }

    &__header {
        @extend .header__main;
        position: fixed;
        width: 100%;
        display: flex;
        justify-content: space-between;
        z-index: 2;
        top: 0;
        left: 0;
        transition: $transition-default;

        @include media-breakpoint-up($navigation-media-breakpoint-up) {
            display: none;
        }

        &--topbar-visible {
            top: map-deep-get($header-height, "top", "xs");

            @include media-breakpoint-up(md) {
                top: map-deep-get($header-height, "top", "md");
            }

            @include media-breakpoint-up(lg) {
                top: map-deep-get($header-height, "top", "lg");
            }

            @include media-breakpoint-up(xl) {
                top: map-deep-get($header-height, "top", "xl");
            }
        }
    }

    &__header-buttons {
        display: flex;
        margin-left: auto;
    }

    &__list {
        width: 100%;

        @include media-breakpoint-up($navigation-media-breakpoint-up) {
            width: auto;
        }

        /* stylelint-disable */
        // TODO: Nette oplossing verzinnen
        > .navigation-item {
            &:last-child {
                .navigation-item__link {
                    @include media-breakpoint-up($navigation-media-breakpoint-up) {
                        padding-right: 0;
                    }
                }
            }
        }

        /* stylelint-enable */
    }

    &--active {
        opacity: 1;
        pointer-events: auto;

        /* stylelint-disable */
        // TODO: Nette oplossing verzinnen
        .navigation-item {
            opacity: 1;
            padding: 5px 0;
        }

        /* stylelint-enable */
    }

    &--topbar-visible {
        top: map-deep-get($header-height, "top", "xs");

        @include media-breakpoint-up(md) {
            top: map-deep-get($header-height, "top", "md");
        }

        @include media-breakpoint-up(lg) {
            top: map-deep-get($header-height, "top", "lg");
        }

        @include media-breakpoint-up(xl) {
            top: map-deep-get($header-height, "top", "xl");
        }
    }
}
