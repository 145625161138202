.page-submenu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 90;
    background: #ffffff;
    transition: all .3s ease-in-out;
    transform: translateX(-100%);
    margin: 50px 0 0;
    padding: 60px 30px 0;
    $elm: &;

    @include media-breakpoint-up(md) {
        position: static;
        transform: none;
        margin: 0 0 1.5rem;
        padding: 0;
    }

    &--expanded {
        transform: translateX(0);
        box-shadow: 0 5px 30px rgba(map-deep-get($colors, "black", "default"), .5);
    }

    &__title {
        font-size: 1.5rem;
        color: map-deep-get($colors, "brand", "primary");
        margin-top: 2rem;
    }

    &__col {
        margin-bottom: 20px;
    }

    &__item {
        background-color: map-deep-get($colors, "brand", "primary");
        color: map-deep-get($colors, "white", "default");
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 1rem 2rem 1rem 1rem;
        position: relative;

        @include media-breakpoint-up(md) {
            padding: 1.25rem 2.5rem 1.25rem 1.25rem;
        }

        &:hover {
            color: map-deep-get($colors, "white", "default");
            text-decoration: none;

            #{$elm}__hover-icon {
                @include media-breakpoint-up(xl) {
                    transform: translate(50%, -50%) scale(1);
                }
            }
        }
    }

    &__hover-icon {
        position: absolute;
        top: 50%;
        right: 100%;
        transform: translate(50%, -50%) scale(0);
        transition: all .3s ease-in-out;

        svg {
            width: 30px;
            height: 30px;

            /* stylelint-disable */
            path {
                fill: map-deep-get($colors, "brand", "secondary");
            }

            /* stylelint-enable */
        }
    }

    &__arrow {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);

        @include media-breakpoint-up(md) {
            right: 1.25rem;
        }
    }

    &__results-count {
        margin: 2.5rem 0 0;
    }
}
