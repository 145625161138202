.usp-list {
    margin-bottom: 1em;
    $elm: &;

    &__list-item {
        position: relative;
        padding-left: 1.5em;
        margin-bottom: .5em;
        color: map-deep-get($colors, "black", "default");

        &::before {
            content: "";
            position: absolute;
            top: .125em;
            left: 0;
            width: 1em;
            height: 1em;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1Ljc1IDhDMTUuNzUgNi42MjUgMTUuMzc1IDUuMzEyNSAxNC42ODc1IDQuMTI1QzE0IDIuOTM3NSAxMy4wNjI1IDIgMTEuODc1IDEuMzEyNUMxMC42ODc1IDAuNjI1IDkuMzc1IDAuMjUgOCAwLjI1QzYuNTkzNzUgMC4yNSA1LjMxMjUgMC42MjUgNC4xMjUgMS4zMTI1QzIuOTM3NSAyIDEuOTY4NzUgMi45Mzc1IDEuMjgxMjUgNC4xMjVDMC41OTM3NSA1LjMxMjUgMC4yNSA2LjYyNSAwLjI1IDhDMC4yNSA5LjQwNjI1IDAuNTkzNzUgMTAuNjg3NSAxLjI4MTI1IDExLjg3NUMxLjk2ODc1IDEzLjA2MjUgMi45Mzc1IDE0LjAzMTIgNC4xMjUgMTQuNzE4OEM1LjMxMjUgMTUuNDA2MiA2LjU5Mzc1IDE1Ljc1IDggMTUuNzVDOS4zNzUgMTUuNzUgMTAuNjg3NSAxNS40MDYyIDExLjg3NSAxNC43MTg4QzEzLjA2MjUgMTQuMDMxMiAxNCAxMy4wNjI1IDE0LjY4NzUgMTEuODc1QzE1LjM3NSAxMC42ODc1IDE1Ljc1IDkuNDA2MjUgMTUuNzUgOFpNNy4wOTM3NSAxMi4wOTM4QzcgMTIuMjE4OCA2Ljg3NSAxMi4yNSA2Ljc1IDEyLjI1QzYuNTkzNzUgMTIuMjUgNi40Njg3NSAxMi4yMTg4IDYuNDA2MjUgMTIuMDkzOEwzLjE1NjI1IDguODQzNzVDMy4wMzEyNSA4Ljc4MTI1IDMgOC42NTYyNSAzIDguNUMzIDguMzc1IDMuMDMxMjUgOC4yNSAzLjE1NjI1IDguMTU2MjVMMy44NDM3NSA3LjQzNzVDMy45Mzc1IDcuMzQzNzUgNC4wNjI1IDcuMjgxMjUgNC4xODc1IDcuMjgxMjVDNC4zMTI1IDcuMjgxMjUgNC40Mzc1IDcuMzQzNzUgNC41NjI1IDcuNDM3NUw2Ljc1IDkuNjI1TDExLjQzNzUgNC45Mzc1QzExLjUzMTIgNC44NDM3NSAxMS42NTYyIDQuNzgxMjUgMTEuNzgxMiA0Ljc4MTI1QzExLjkwNjIgNC43ODEyNSAxMi4wMzEyIDQuODQzNzUgMTIuMTU2MiA0LjkzNzVMMTIuODQzOCA1LjY1NjI1QzEyLjkzNzUgNS43NSAxMyA1Ljg3NSAxMyA2QzEzIDYuMTU2MjUgMTIuOTM3NSA2LjI4MTI1IDEyLjg0MzggNi4zNDM3NUw3LjA5Mzc1IDEyLjA5MzhaIiBmaWxsPSIjNkFCMDIzIi8+Cjwvc3ZnPgo=");
        }
    }

    &--white {
        #{$elm}__list-item {
            color: map-deep-get($colors, "white", "default");

            &::before {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1Ljc1IDhDMTUuNzUgNi42MjUgMTUuMzc1IDUuMzEyNSAxNC42ODc1IDQuMTI1QzE0IDIuOTM3NSAxMy4wNjI1IDIgMTEuODc1IDEuMzEyNUMxMC42ODc1IDAuNjI1IDkuMzc1IDAuMjUgOCAwLjI1QzYuNTkzNzUgMC4yNSA1LjMxMjUgMC42MjUgNC4xMjUgMS4zMTI1QzIuOTM3NSAyIDEuOTY4NzUgMi45Mzc1IDEuMjgxMjUgNC4xMjVDMC41OTM3NSA1LjMxMjUgMC4yNSA2LjYyNSAwLjI1IDhDMC4yNSA5LjQwNjI1IDAuNTkzNzUgMTAuNjg3NSAxLjI4MTI1IDExLjg3NUMxLjk2ODc1IDEzLjA2MjUgMi45Mzc1IDE0LjAzMTIgNC4xMjUgMTQuNzE4OEM1LjMxMjUgMTUuNDA2MiA2LjU5Mzc1IDE1Ljc1IDggMTUuNzVDOS4zNzUgMTUuNzUgMTAuNjg3NSAxNS40MDYyIDExLjg3NSAxNC43MTg4QzEzLjA2MjUgMTQuMDMxMiAxNCAxMy4wNjI1IDE0LjY4NzUgMTEuODc1QzE1LjM3NSAxMC42ODc1IDE1Ljc1IDkuNDA2MjUgMTUuNzUgOFpNNy4wOTM3NSAxMi4wOTM4QzcgMTIuMjE4OCA2Ljg3NSAxMi4yNSA2Ljc1IDEyLjI1QzYuNTkzNzUgMTIuMjUgNi40Njg3NSAxMi4yMTg4IDYuNDA2MjUgMTIuMDkzOEwzLjE1NjI1IDguODQzNzVDMy4wMzEyNSA4Ljc4MTI1IDMgOC42NTYyNSAzIDguNUMzIDguMzc1IDMuMDMxMjUgOC4yNSAzLjE1NjI1IDguMTU2MjVMMy44NDM3NSA3LjQzNzVDMy45Mzc1IDcuMzQzNzUgNC4wNjI1IDcuMjgxMjUgNC4xODc1IDcuMjgxMjVDNC4zMTI1IDcuMjgxMjUgNC40Mzc1IDcuMzQzNzUgNC41NjI1IDcuNDM3NUw2Ljc1IDkuNjI1TDExLjQzNzUgNC45Mzc1QzExLjUzMTIgNC44NDM3NSAxMS42NTYyIDQuNzgxMjUgMTEuNzgxMiA0Ljc4MTI1QzExLjkwNjIgNC43ODEyNSAxMi4wMzEyIDQuODQzNzUgMTIuMTU2MiA0LjkzNzVMMTIuODQzOCA1LjY1NjI1QzEyLjkzNzUgNS43NSAxMyA1Ljg3NSAxMyA2QzEzIDYuMTU2MjUgMTIuOTM3NSA2LjI4MTI1IDEyLjg0MzggNi4zNDM3NUw3LjA5Mzc1IDEyLjA5MzhaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
            }
        }
    }
}
