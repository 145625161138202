.news-overview {
    margin: 4rem 0 1.75rem;

    &__col {
        margin-bottom: 20px;
    }

    &__item {
        display: flex;
        flex-direction: column;
        height: 100%;

        &:hover {
            text-decoration: none;
        }
    }

    &__info-wrapper {
        border: 1px solid map-deep-get($colors, "gray", "dark");
        padding: 2rem;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 225px;

        @include media-breakpoint-up(sm) {
            height: 250px;
        }

        @include media-breakpoint-up(md) {
            height: 300px;
        }

        @include media-breakpoint-up(xl) {
            height: 380px;
        }
    }

    &__title {
        margin: 0 0 1.5rem;
        border-bottom: 0;
        font-size: 1.5rem;
        line-height: 1;
        flex-grow: 1;
    }

    &__read-more {
        justify-content: center;
    }
}
