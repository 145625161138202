.header-spacer {
    width: 100%;
    padding-top: #{map-deep-get($header-height, "main", "xs")};

    @include media-breakpoint-up(md) {
        padding-top: #{map-deep-get($header-height, "main", "md")};
    }

    @include media-breakpoint-up(lg) {
        padding-top: #{map-deep-get($header-height, "main", "lg") + .75rem};
    }

    @include media-breakpoint-up(xl) {
        padding-top: #{map-deep-get($header-height, "main", "xl") + .75rem};
    }

    &--has-topbar {
        padding-top: #{map-deep-get($header-height, "top", "xs") + map-deep-get($header-height, "main", "xs")};

        @include media-breakpoint-up(md) {
            padding-top: #{map-deep-get($header-height, "top", "md") + map-deep-get($header-height, "main", "md")};
        }

        @include media-breakpoint-up(lg) {
            padding-top: #{map-deep-get($header-height, "top", "lg") + map-deep-get($header-height, "main", "lg")};
        }

        @include media-breakpoint-up(xl) {
            padding-top: #{map-deep-get($header-height, "top", "xl") + map-deep-get($header-height, "main", "xl")};
        }
    }
}
