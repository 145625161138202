.pagination {
    &__list {
        display: inline-flex;
        height: 100%;
    }

    &__list-item {
        border: 1px solid map-deep-get($colors, "gray", "dark");
        display: none;
        height: 100%;
        margin-right: -1px;

        @include media-breakpoint-up(lg) {
            display: inline-block;
        }

        &--control {
            display: inline-block;
        }

        &--mobile {
            border: none;
            font-size: #{(14px / 16px) * 1rem};
            line-height: 48px;
            margin: 0 10px;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }

    &__list-link,
    &__list-icon,
    &__control-link {
        display: block;
        color: map-deep-get($colors, "black", "default");
        line-height: 1em;
        padding: calc(((50px - 2px) - 1em) / 2);
        font-size: #{(14px / 16px) * 1rem};

        &--active {
            background-color: map-deep-get($colors, "brand", "primary-light");
            color: map-deep-get($colors, "brand", "primary");
            font-weight: 700;
        }
    }

    &__control-link {
        color: map-deep-get($colors, "black", "default");
        display: block;
    }
}