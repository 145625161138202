.product-detail {
    &__row {
        gap: 1.5rem 0;
    }

    &__title {
        margin: 0;
    }

    &__category {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
        margin-top: 0;
    }

    &__info-wrapper {
        padding: 2rem 0 3rem;
    }

    &__usps {
        margin: 1.5rem 0;
    }

    &__usp-title {
        margin-top: 0;
    }

    &__quotation-wrapper {
        background-color: map-deep-get($colors, "brand", "primary");
        padding: 3rem 0;

        @media (min-width: 992px) {
            padding: 6rem 0;
        }

        /* stylelint-disable */
        form {
            label,
            a {
                color: map-deep-get($colors, "white", "default");
            }
        }
        /* stylelint-enable */
    }

    &__quotation-info {
        /* stylelint-disable */
        ol {
            counter-reset: my-counter;
            list-style: none;
            padding-left: 72px;
            margin: 4.75rem 0 0;
            font-size: 1.5rem;
            font-weight: 900;
            color: map-deep-get($colors, "white", "default");

            li {
                margin: 0 0 3rem;
                counter-increment: my-counter;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    $size: 56px;
                    left: calc(-#{$size} - 17px);
                    line-height: $size;
                    width: $size;
                    height: $size;
                    top: calc(-#{$size} / 4);
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA1NiA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNy41ODk3IDBDMjEuNTk3NSAxOC41NzI2IDAgMjguMzA5OCAwIDI4LjMwOThDMCAyOC4zMDk4IDguNTE1MjQgMzAuOTc5NyAxNS4xOTM1IDM3Ljc2ODlDMjEuODY0NSA0NC41NTIgMjcuMTE0NiA1NiAyNy4xMTQ2IDU2QzI3LjExNDYgNTYgMzEuNjc2NSA0Ni41NDI5IDM4Ljc2NDYgMzkuMzQyM0M0NS44NDY1IDMyLjE0NTkgNTYgMjguMzc4NSA1NiAyOC4zNzg1QzU2IDI4LjM3ODUgNDMuMDU1MyAyMS44NjUyIDM3LjA1NyAxNS42OTc2QzMxLjA2MzggOS41Mjg5MiAyNy41ODk3IDAgMjcuNTg5NyAwWiIgZmlsbD0iIzZBQjAyMyIvPgo8L3N2Zz4K");
                }

                &::after {
                    content: counter(my-counter);
                    color: map-deep-get($colors, "white", "default");
                    font-size: rem-calc(20);
                    font-weight: 700;
                    position: absolute;
                    $size: 32px;
                    left: calc(-#{$size} - 17px);
                    line-height: $size;
                    width: $size;
                    height: $size;
                    top: 0;
                }
            }
        }
        /* stylelint-enable */
    }

    &__form-title {
        margin-top: 0;
        color: map-deep-get($colors, "white", "default");
        text-align: center;

        @media (min-width: 768px) {
            text-align: left;
        }
    }

    &__accessoires-wrapper {
        padding: 3rem 0 0;

        @media (min-width: 992px) {
            padding: 4rem 0 0;
        }
    }

    &__accessoires-title {
        margin: 0 0 1.5em;
        text-align: center;
    }

    &__actions {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(sm) {
            flex-flow: row wrap;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 3.125rem;
        }
    }

    &__action {
        flex: 0 0 auto;
        justify-content: center;

        @include media-breakpoint-up(md) {
            flex: 1 0 auto;
        }

        @include media-breakpoint-up(lg) {
            flex: 1 0 calc(50% - .5rem);
        }

        &--primary {
            // This color only exist here, otherwise use a variable
            background-color: #da5b00;

            &:hover,
            &:focus-visible {
                background: #b84d00;
            }
        }

        /* stylelint-disable */
        .button {
            width: 100%;
            justify-content: center;
        }
        /* stylelint-enable */
    }

    &__action-info {
        flex: 0 0 100%;

        /* stylelint-disable */
        p {
            margin-bottom: 0;
        }
        /* stylelint-enable */
    }
}
