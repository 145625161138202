.page-nav-menu {
    margin-top: 38px;
    padding: 40px 30px 30px;
    background: #e0eff9;

    &__list-item {
        margin-bottom: 0.8em;
        padding-left: 1.625em;
        font-weight: 900;
        color: #004a99;

        svg {
            height: 1.125em;
            width: 1.125em;
            margin-left: -1.625em;
            margin-right: 0.2em;
        }

        svg path {
            fill: map-deep-get($colors, "brand", "secondary");
        }
    }
}
