.product-advice {
    &__top {
        background: map-deep-get($colors, "brand", "primary-light");
        padding: 1rem 1.25rem;
        display: flex;
        gap: 1rem;
        justify-content: space-between;

        @include media-breakpoint-up(lg) {
            padding: 1rem 1.25rem;
        }
    }

    &__content {
        padding: 1rem 0;

        @include media-breakpoint-up(sm) {
            padding: 1rem 0 1rem .75rem;
        }
    }

    &__title {
        margin-bottom: 1rem;
        margin-top: 0;
        line-height: .875rem;
    }

    &__link {
        display: flex;
        align-items: center;
        color: map-deep-get($colors, "black", "default");
        line-height: 1.625;

        svg {
            margin-right: .5em;
            max-width: 14px;
        }

        svg path {
            fill: map-deep-get($colors, "brand", "primary");
        }
    }

    &__image {
        min-width: 1px;
        min-height: 1px;
        max-width: 130px;
        margin-top: 1em;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }

    &__bottom {
        padding: 10px 20px 20px 30px;
        background: map-deep-get($colors, "brand", "primary");

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: space-between;
            padding: 10px 20px 10px 30px;
        }
    }

    &__cta-title {
        margin-top: .3em;
        margin-bottom: .3em;
        color: map-deep-get($colors, "white", "default");
    }
}
