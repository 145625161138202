.priority-navigation {
    $block: &;

    &--inverted {
        #{$block}__link {
            @include media-breakpoint-up($navigation-media-breakpoint-up) {
                color: map-deep-get($colors, "white", "default");
            }

            svg path {
                @include media-breakpoint-up($navigation-media-breakpoint-up) {
                    fill: map-deep-get($colors, "white", "default");
                }
            }
        }

        #{$block}__icon {
            svg path {
                @include media-breakpoint-up($navigation-media-breakpoint-up) {
                    fill: map-deep-get($colors, "white", "default");
                }
            }
        }
    }

    &__list {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        overflow-x: auto;

        @include media-breakpoint-up(md) {
            height: map-deep-get($header-height, "top", "md");
        }

        @include media-breakpoint-up(lg) {
            height: map-deep-get($header-height, "top", "lg");
        }

        @include media-breakpoint-up(xl) {
            height: map-deep-get($header-height, "top", "xl");
        }

        @include media-breakpoint-up($navigation-media-breakpoint-up) {
            overflow-x: visible;
            justify-content: flex-end;
        }

        &::before {
            @include media-breakpoint-down($navigation-media-breakpoint-up) {
                content: "";
                display: block;
                position: absolute;
                z-index: 1;
                opacity: 0;
                top: 0;
                left: 10px;
                width: 6rem;
                height: map-deep-get($header-height, "top", "md");
                background: linear-gradient(135deg, map-deep-get($colors, "brand", "secondary") 0%, rgba(map-deep-get($colors, "brand", "secondary"), 0) 100%);
                pointer-events: none;
                transition: $transition-default;
            }
        }

        &::after {
            @include media-breakpoint-down($navigation-media-breakpoint-up) {
                content: "";
                display: block;
                position: absolute;
                z-index: 1;
                opacity: 0;
                top: 0;
                right: 10px;
                width: 6rem;
                height: map-deep-get($header-height, "top", "md");
                background: linear-gradient(270deg, map-deep-get($colors, "brand", "secondary") 0%, rgba(map-deep-get($colors, "brand", "secondary"), 0) 100%);
                pointer-events: none;
                transition: $transition-default;
            }
        }

        &--scrolled-left {
            &::before {
                opacity: 1;
            }
        }

        &--scrolled-right {
            &::after {
                opacity: 1;
            }
        }
    }

    &__search {
        align-self: center;

        @media (max-width: 991px) {
            &.active {
                width: 200px;
                right: 40px;

                &::before {
                    right: 30px;
                }

                /*stylelint-disable*/
                input {
                    &::placeholder {
                        color: #6ab023;
                    }

                    &[type=text] {
                        width: auto;
                    }
                }

                /*stylelint-enable*/
            }
        }

        /*stylelint-disable*/
        #submit {
            background: url("/images/icons/search-icon.svg") no-repeat;
            position: absolute;
            top: 4px;
            border: none;
            right: 0;
            cursor: pointer;
            width: 10px;
            height: 17px;
        }

        input {
            &::placeholder {
                color: #6ab023;
            }

            &[type=text] {
                border: none;
                height: unset;
                color: #6ab023;
                font-size: rem-calc(14);
                line-height: 24px;
                padding: 2px 18px 0;
                background: #d2e7bd;
                border-radius: 25px;
                max-width: 230px;
            }
        }

        /*stylelint-enable*/
    }

    &__list-item {
        position: relative;
        flex: none;
        margin: 0 .75rem;
        white-space: nowrap;

        &:hover,
        &:focus,
        &:focus-within {
            > #{$block}__list-item--sub {
                transform: translateY(0);
                opacity: 1;
                pointer-events: visible;
            }

            > #{$block}__link {
                color: map-deep-get($colors, "black", "default");
                text-decoration: none;
                transition: $transition-default;
            }

            #{$block}__link #{$block}__sub-icon svg path {
                fill: map-deep-get($colors, "black", "default");
                transition: $transition-default;
            }

            #{$block}__link #{$block}__icon svg path {
                fill: map-deep-get($colors, "brand", "primary");
            }
        }

        @include media-breakpoint-up($navigation-media-breakpoint-up) {
            margin: 0 0.75rem;
        }

        &:first-child {
            @include media-breakpoint-down($navigation-media-breakpoint-down) {
                margin-left: 0;
            }
        }

        &:last-child {
            @include media-breakpoint-up($navigation-media-breakpoint-up) {
                margin-right: 0;
            }
        }

        &--sub {
            position: absolute;
            z-index: 9;
            top: 100%;
            left: 0;
            margin-left: 0;
            padding: 0.5rem 1rem;
            color: map-deep-get($colors, "black", "default");
            background: map-deep-get($colors, "white", "default");
            box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.2);
            transform: translateY(-20px);
            opacity: 0;
            pointer-events: none;
            transition: $transition-default;

            @include media-breakpoint-down($navigation-media-breakpoint-down) {
                display: none;
            }

            #{$block}__list-item {
                margin: 0;
            }

            #{$block}__link {
                color: map-deep-get($colors, "brand", "primary");
                transition: $transition-default;
            }
        }
    }

    &__link {
        display: inline-flex;
        align-items: center;
        font-weight: 700;
        font-size: .875rem;
        letter-spacing: .025em;
        color: map-deep-get($colors, "white", "default");
        height: map-deep-get($header-height, "top", "xs");

        @include media-breakpoint-up(md) {
            height: map-deep-get($header-height, "top", "md");
        }

        @include media-breakpoint-up(lg) {
            height: map-deep-get($header-height, "top", "lg");
        }

        @include media-breakpoint-up(xl) {
            height: map-deep-get($header-height, "top", "xl");
        }
    }

    &__sub-icon {
        margin-left: 0.375rem;

        @include media-breakpoint-down($navigation-media-breakpoint-down) {
            display: none;
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &__icon {
        svg {
            margin-right: .5rem;
            width: 16px;
            height: 16px;
            vertical-align: sub;
        }

        svg path {
            transition: $transition-default;
            fill: map-deep-get($colors, "brand", "secondary");
        }
    }

    &__show-mobile {
        display: inline-block;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}
