.zigzag-content {
    &__row {
        &:not(&:nth-last-child(1)) {
            @media (min-width: 768px) {
                margin-bottom: 4rem;
            }
        }
    }

    &__col {
        &--text {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &__image-wrapper {
        min-height: 500px;

        @include media-breakpoint-up(lg) {
            min-height: 600px;
        }
    }

    &__image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;
        min-height: inherit;
    }

    &__text {
        /* stylelint-disable */
        h2 {
            margin-top: 0;
        }
        /* stylelint-enable */
    }
}
