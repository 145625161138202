.searchform {
    background: #eaeaea;
    padding: 25px 10px;

    /*stylelint-disable*/
    input {
        &[type=submit] {
            background: #6ab023;
            color: #ffffff
        }
    }

    /*stylelint-enable*/
}
