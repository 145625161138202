.product-slider {
    $elm: &;
    position: relative;

    @include media-breakpoint-up(md) {
        position: sticky;
        top: #{map-deep-get($header-height, "main-scrolled", "md") + map-deep-get($header-height, "top", "md")};
    }

    @include media-breakpoint-up(lg) {
        top: #{map-deep-get($header-height, "main-scrolled", "lg") + map-deep-get($header-height, "top", "lg")};
    }

    @include media-breakpoint-up(xl) {
        top: #{map-deep-get($header-height, "main-scrolled", "xl") + map-deep-get($header-height, "top", "xl")};
    }

    &__main {
        border: 1px solid map-deep-get($colors, "gray", "dark");
        height: 250px;
        padding: 2rem;

        @include media-breakpoint-up(md) {
            height: 200px;
        }

        @include media-breakpoint-up(lg) {
            height: 350px;
        }

        @include media-breakpoint-up(lg) {
            padding: 3.375rem 4.75rem;
        }

        /* stylelint-disable */
        .flickity-button {
            &.previous,
            &.next {
                display: none;
            }
        }

        &.is-fullscreen {
            border-color: transparent;

            .flickity-button {
                &.previous,
                &.next {
                    display: block;
                }
            }
        }
        /* stylelint-enable */
    }

    &__label {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px 30px;
        border-top-left-radius: 0;
        z-index: 10;
    }

    /* stylelint-disable */
    &__slide {
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: contain;
        background-size: contain;
        display: flex;
        height: 100%;
        width: 100%;
    }
    /* stylelint-enable */

    // slider nav

    &__nav {
        margin-left: -5px;
        margin-right: -5px;
        margin-top: 10px;
    }

    &__slide-nav {
        display: inline-block;
        height: 100px;
        padding: 0 5px;
        width: 33.333%;

        @include media-breakpoint-up(md) {
            height: 115px;
        }

        &.is-selected #{$elm + "__slide-nav-image-wrapper"} {
            border-color: map-deep-get($colors, "brand", "secondary");
        }
    }
    /* stylelint-disable */
    &__slide-nav-image-wrapper {
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: contain;
        background-size: contain;
        border: 1px solid map-deep-get($colors, "gray", "dark");
        cursor: pointer;
        display: inline-flex;
        padding: 10px;
        height: 100%;
        width: 100%;
    }
    /* stylelint-enable */

    &__slide-nav-image {
        display: inline-block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.flickity-slider{
    cursor: pointer;
}

/* stylelint-disable */
#imgClose{
    position: fixed;
    right: 10%;
    top: 20px;
    color: #ffffff;
    font-size: 40px;
    background: none;
    z-index: 9999;
    border: none;
    outline: none;
    padding: 0 0 0 0;

    @media only screen and (max-width: 768px) {
        top: 5%;
        right: 25px;
        padding: 0 0 0 0;
    }
}

#imgLeft{
    position: fixed;
    left: 10%;
    top: 40%;
    color: #ffffff;
    height: 30px;
    width: 30px;
    font-size: 40px;
    z-index: 9999;
    border: none;
    background: url('../images/left.svg') no-repeat;
    outline: none;
    padding: 0 0 0 0;

    @media only screen and (max-width: 768px) {
        top: 45%;
        left: 25px;
        padding: 0 0 0 0;
    }
}

#imgRight{
    position: fixed;
    right: 10%;
    top: 40%;
    color: #ffffff;
    height: 30px;
    width: 30px;
    font-size: 40px;
    z-index: 9999;
    border: none;
    background: url('../images/right.svg') no-repeat;
    outline: none;
    padding: 0 0 0 0;

    @media only screen and (max-width: 768px) {
        top: 45%;
        right: 25px;
        padding: 0 0 0 0;
    }
}
/* stylelint-enable */
