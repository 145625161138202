.hero {
    position: relative;
    margin-top: -20px;
    height: 400px;
    text-align: center;
    $elm: &;

    @include media-breakpoint-up(md) {
        height: 600px;
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, rgba(map-deep-get($colors, "black", "default"), 0) 20%, rgba(0, 19, 51, 0.7) 100%);
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    &__video-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    &__video {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: auto;
        min-width: 100%;
        min-height: 100%;

        @include media-breakpoint-up(md) {
            display: block;
        }

        &--fullscreen {
            display: block;
            position: static;
            transform: none;
            min-width: unset;
            min-height: unset;
        }

        &--no-fullscreen {
            position: static;
            transform: none;
            min-width: unset;
            min-height: unset;
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        margin: 0 auto;
        padding: 0 10px;
        width: 100%;
        max-width: 1200px;
        height: 100%;
        text-align: left;
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    &__title {
        color: map-deep-get($colors, "white", "default");
    }

    &__button {
        margin-right: 1em;
    }

    &__play-video-link {
        display: inline-block;
        margin-top: .8em;
        color: map-deep-get($colors, "white", "default");

        svg {
            margin-right: .5em;
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }

        &:hover {
            text-decoration: none;
            color: map-deep-get($colors, "brand", "secondary");
            transition: $transition-default;

            svg path {
                fill: map-deep-get($colors, "brand", "secondary");
                transition: $transition-default;
            }
        }
    }

    &__diamond-shape {
        pointer-events: none;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;

        svg {
            float: right;
            height: 200%;
            width: auto;
            transform: translateX(50%) scale(1.25);
        }
    }

    &__diamond-shape1 {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;

        svg path {
            fill: rgba(map-deep-get($colors, "brand", "secondary"), 0.4);
        }
    }

    &__diamond-shape2 {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;

        svg path {
            fill: rgba(map-deep-get($colors, "brand", "secondary"), 0.3);
        }
    }

    &--compact {
        height: 300px;

        @include media-breakpoint-up(md) {
            height: 400px;
        }

        &::after {
            display: none;
        }
    }
}