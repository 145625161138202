.main {
    overflow: hidden;
    max-width: 100%;
    padding-bottom: 50px;
    transition: $transition-default;
    flex: 1 1 100%;

    &--show-overflow {
        overflow: visible;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 120px;
    }
}
