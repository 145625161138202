.interest-block {
    background-color: map-deep-get($colors, "brand", "primary");
    margin-bottom: 2rem;
    padding: 30px;
    position: relative;

    @media (min-width: 992px) {
        margin-bottom: 4rem;
        padding: 40px;
    }

    &__title {
        color: map-deep-get($colors, "white", "default");
        margin: 0 0 1.5rem;

        @media (min-width: 992px) {
            font-size: 2rem;
        }
    }

    &__line {
        color: map-deep-get($colors, "white", "default");

        svg {
            fill: map-deep-get($colors, "brand", "secondary");
            margin-right: .8rem;
        }
    }

    &__button {
        margin: 1rem 0 0;
        justify-content: center;
        width: 100%;

        @media (min-width: 992px) {
            margin: 1.5rem 1rem 0 0;
            justify-content: normal;
            width: auto;
        }
    }

    &__icon {
        height: 2.5rem;
        width: 2.5rem;
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(50%, 50%);
        display: none;

        @media (min-width: 1200px) {
            display: block;
        }

        svg {
            fill: map-deep-get($colors, "brand", "secondary");
        }
    }
}
