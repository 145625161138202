.usp-block {
    margin-top: 2em;
    padding: 36px 30px;
    color: map-deep-get($colors, "white", "default");
    background-color: map-deep-get($colors, "brand", "primary");

    &__title {
        margin-top: 0;
        color: map-deep-get($colors, "white", "default");
    }
}