.quicklinks {
    position: relative;
    z-index: 1;
    margin-top: calc(-2em);
    margin-bottom: 2em;
    background: map-deep-get($colors, "brand", "primary");
    $elm: &;

    @include media-breakpoint-up(md) {
        margin-top: calc(-1em - 100px);
        margin-bottom: calc(-2em - 200px);
    }

    &__list-item {
        position: relative;
        border-bottom: 1px solid rgba(map-deep-get($colors, "gray", "dark"), 0.3);

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            #{$elm}__hover-icon {
                transform: translateY(-50%) scale(1);
            }
        }
    }

    &__hover-icon {
        position: absolute;
        top: 50%;
        left: -20px;
        transform: translateY(-50%) scale(0);
        transition: $transition-default;

        svg {
            width: 40px;
            height: 40px;
        }

        svg path {
            fill: map-deep-get($colors, "brand", "secondary");
        }
    }

    &__link {
        display: inline-block;
        padding: 46px 35px;
        width: 100%;
        font-weight: 900;
        color: map-deep-get($colors, "white", "default");

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(24);
        }

        &:hover {
            text-decoration: none;
            color: map-deep-get($colors, "white", "default");
        }

        svg {
            display: inline-block;
            margin-top: 0.15em;
            float: right;

            @include media-breakpoint-up(md) {
                margin-top: .4em;
            }
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }
    }
}
