.featured-page {
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-bottom: 1em;
    height: 280px;
    background-size: cover;
    background-position: center;
    transition: $transition-default;
    $elm: &;

    @include media-breakpoint-up(md) {
        margin-bottom: 140px;
    }

    &:hover {
        z-index: 1;
        text-decoration: none;
        transform: scale(1.15);

        &::after {
            opacity: 0;
        }

        #{$elm}__title {
            font-size: calc(#{rem-calc(24)} / 1.15);
            transform: translateY(100%);
            background: map-deep-get($colors, "brand", "primary");
        }

        #{$elm}__hover-icon {
            transform: translateX(-50%) scale(1);
        }
    }
    /*stylelint-disable*/
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 116px;
        bottom: 0;
        left: 0;
        width: 100%;
        background: -webkit-gradient(linear, right top, left top, color-stop(20%, rgba(0, 0, 0, 0)), to(rgba(0, 19, 51 ,.7)));
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 19, 51, 0.6) 100%);
        transition: $transition-default;
    }
    /*stylelint-enable*/

    &__title {
        position: relative;
        z-index: 1;
        margin: 0;
        padding: 30px 0;
        width: 100%;
        text-align: center;
        color: map-deep-get($colors, "white", "default");
        transition: $transition-default;
    }

    &__hover-icon {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%) scale(0);
        transition: $transition-default;

        svg {
            width: 40px;
            height: 40px;
        }

        svg path {
            fill: map-deep-get($colors, "brand", "secondary");
        }
    }
}
