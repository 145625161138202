/* stylelint-disable */
#CybotCookiebotDialog {
    #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink {
        &.CybotCookiebotDialogActive {
            border-bottom-color: map-deep-get($colors, "brand", "primary") !important;
            color: map-deep-get($colors, "brand", "primary") !important;
        }
        &:hover,
        &:focus-visible {
            color: map-deep-get($colors, "brand", "primary") !important;
        }
    }
    .CybotCookiebotDialogDetailBodyContentCookieLink,
    #CybotCookiebotDialogBodyEdgeMoreDetailsLink,
    #CybotCookiebotDialogBodyLevelButtonsSelectPane label:not([for=CybotCookiebotDialogBodyLevelButtonNecessary]) .CybotCookiebotDialogBodyLevelButtonDescription:hover,
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount):hover {
        color: map-deep-get($colors, "brand", "primary") !important;
    }
    #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
        &::after {
            border-color: map-deep-get($colors, "brand", "primary") !important;
            position: relative;
            top: .0625rem;
        }
    }
    .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton {
        transition: $transition-default;
        &:hover,
        &:focus-visible {
            color: map-deep-get($colors, "brand", "primary") !important;
            &:before {
                border-color: map-deep-get($colors, "brand", "primary") !important;
            }
        }
        &::before {
            transition: $transition-default;
        }
    }
    .CybotCookiebotDialogBodyButton {
        border-color: map-deep-get($colors, "brand", "primary") !important;
        transition: $transition-default;
        &:hover,
        &:focus-visible {
            color: map-deep-get($colors, "brand", "primary");
            background-color: rgba(map-deep-get($colors, "brand", "primary"), .05) !important;
            border-color: rgba(map-deep-get($colors, "brand", "primary"), .75) !important;
            opacity: 1 !important;
        }
    }
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
        background-color: map-deep-get($colors, "white", "default") !important;
        color: map-deep-get($colors, "brand", "primary") !important;
        &:hover,
        &:focus-visible {
            background-color: rgba(map-deep-get($colors, "brand", "primary"), .05) !important;
            border-color: rgba(map-deep-get($colors, "brand", "primary"), .75) !important;
            color: map-deep-get($colors, "brand", "primary") !important;
            opacity: 1 !important;
        }
    }
    #CybotCookiebotDialogDetailFooter a {
        color: map-deep-get($colors, "brand", "primary") !important;
    }
    input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
        background-color: map-deep-get($colors, "brand", "primary") !important;
    }
    input[type=checkbox],
    input[type=radio] {
        z-index: 0;
    }
}
#CookiebotWidget {
    .CookiebotWidget-body .CookiebotWidget-consents-list svg {
        fill: map-deep-get($colors, "brand", "primary") !important;
    }
    .CookiebotWidget-show-consent-details {
        color: map-deep-get($colors, "brand", "primary") !important;
        svg {
            fill: map-deep-get($colors, "brand", "primary") !important;
            position: relative;
            top: .0625rem;
        }
    }
    #CookiebotWidget-buttons button {
        border-color: map-deep-get($colors, "brand", "primary") !important;
        transition: $transition-default;
        &:hover,
        &:focus-visible {
            background-color: rgba(map-deep-get($colors, "brand", "primary"), .05) !important;
            border-color: rgba(map-deep-get($colors, "brand", "primary"), .75) !important;
            opacity: 1 !important;
        }
    }
    #CookiebotWidget-buttons #CookiebotWidget-btn-change {
        background-color: map-deep-get($colors, "brand", "primary") !important;
        &:hover,
        &:focus-visible {
            background-color: rgba(map-deep-get($colors, "brand", "primary"), .75) !important;
        }
    }
}
/* stylelint-enable */
