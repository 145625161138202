// Rich text styling layout
/* stylelint-disable */
.rich-text-area {
    -webkit-font-smoothing: antialiased;
    line-height: 1.937em;

    .intro {
        font-size: rem-calc(20);
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 1.8em;
    }

    img {
        max-width: 100%;
    }

    a:not(.button) {
        text-decoration: underline;
    }

    ul {
        margin: rem-calc(20) 0;

        li {
            position: relative;
            padding-left: 20px;
            margin-bottom: 0;

            &::before {
                content: "";
                background: map-deep-get($colors, "brand", "primary");
                width: 0.5em;
                height: 0.5em;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: .65em;
            }
        }

        ul {
            margin: 0;
        }
    }

    ol {
        counter-reset: my-counter;
        list-style: none;
        padding-left: 72px;
        margin: 2em 0 0;
        font-size: 24px;
        font-weight: 900;
        color: map-deep-get($colors, "brand", "primary");

        li {
            margin: 0 0 3rem 0;
            counter-increment: my-counter;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                $size: 56px;
                left: calc(-#{$size} - 17px);
                line-height: $size;
                width: $size;
                height: $size;
                top: calc(-#{$size} / 4);
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA1NiA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNy41ODk3IDBDMjEuNTk3NSAxOC41NzI2IDAgMjguMzA5OCAwIDI4LjMwOThDMCAyOC4zMDk4IDguNTE1MjQgMzAuOTc5NyAxNS4xOTM1IDM3Ljc2ODlDMjEuODY0NSA0NC41NTIgMjcuMTE0NiA1NiAyNy4xMTQ2IDU2QzI3LjExNDYgNTYgMzEuNjc2NSA0Ni41NDI5IDM4Ljc2NDYgMzkuMzQyM0M0NS44NDY1IDMyLjE0NTkgNTYgMjguMzc4NSA1NiAyOC4zNzg1QzU2IDI4LjM3ODUgNDMuMDU1MyAyMS44NjUyIDM3LjA1NyAxNS42OTc2QzMxLjA2MzggOS41Mjg5MiAyNy41ODk3IDAgMjcuNTg5NyAwWiIgZmlsbD0iIzZBQjAyMyIvPgo8L3N2Zz4K");
            }

            &::after {
                content: counter(my-counter);
                color: map-deep-get($colors, "white", "default");;
                font-size: rem-calc(20);
                font-weight: bold;
                position: absolute;
                $size: 32px;
                left: calc(-#{$size} - 17px);
                line-height: $size;
                width: $size;
                height: $size;
                top: 0;
            }
        }
    }

    &--configurator {
        text-align: center;

        .button {
            margin: 0 .25rem .5rem;
        }
    }
}

/* stylelint-enable */
