.advice-block {
    padding: 20px 30px;
    background-color: map-deep-get($colors, "brand", "primary-light");

    &__image-wrapper {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__image {
        min-width: 1px;
        min-height: 1px;
        max-width: 100%;
    }

    &__title {
        margin-top: .5em;
    }

    &__text {
        margin-top: .5em;
    }

    &__contact-options {
        margin-top: 1em;
        margin-bottom: .5em;
    }

    &__contact-option {
        display: inline-block;
        margin-right: 2em;
        margin-bottom: .5em;
        width: 100%;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            width: auto;
        }

        svg {
            vertical-align: baseline;
        }

        &--name {
            font-weight: 900;
        }

        &--whatsapp {
            svg path {
                fill: map-deep-get($colors, "brand", "secondary");
            }
        }

        &--phone,
        &--mail {
            svg path {
                fill: map-deep-get($colors, "brand", "primary");
            }
        }

        &--phone {
            svg {
                vertical-align: text-top;
            }
        }
    }

    &__link {
        color: map-deep-get($colors, "black", "default");
    }

    &__button-wrapper {
        display: flex;
        align-items: center;
        height: 100%;
    }
}
